import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FaArrowLeft } from 'react-icons/fa';
import ReactLoading from "react-loading";

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { MyDropzone }  from "../../Components/MyDropzone";
import { ReturnButton } from "../AdicionarArquivo/styles";
import { EditButton } from "../EditarArquivo/styles";
import { InputBox, Input, UploadSelection, UploadOptions, Container } from './styles'
import api from "../../Services/api"

export function EditarMedia() {

    const { id } = useParams();
    const [newTitle, setNewTitle] = useState("");
    const [newDescription, setNewDescription] = useState("");

    const [newUploadType, setNewUploadType] = useState("");
    const [newFileUpload, setNewFileUpload] = useState("");
    const [newFileLink, setNewFileLink] = useState("")

    const [loading, setLoading] = useState();

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/editar-arquivo/` + id)
    }

    useEffect(() => {
        const getFile =  async () => {
            setLoading(true);
            try {
                const response = await api.get("/file/" + id);
                setNewTitle(response.data.title);
                setNewDescription(response.data.description);
                setNewUploadType(response.data.type);
                setNewFileUpload(response.data.media_path);
                setNewFileLink(response.data.media_path);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        getFile();
    }, []);

    const handleEditUpload = async(file) => {
        setLoading(true);
        try {
            uploadDelete(file);
            uploadSubmit();
        } catch (error) {
            console.log(error);
            alert("Erro ao editar arquivo!");
        }
        setLoading(false);
    }

    const uploadDelete = async(file) => {
        setLoading(true);
        try {
            await api.delete("/media/" + file.media_path);
        } catch (error) {
            console.log(error);
            alert("Erro ao deletar upload!");
        }
        setLoading(false);
    };

    const uploadSubmit = async() => {
        setLoading(true);
        try {
            const formData = new FormData();
            const contentType_map = {
                'image/png':'png',
                'image/jpeg':'jpeg',
                'image/jpg':'jpg',
                'image/gif':'gif',
                'video/mp4':'mp4',
                'video/webm':'webm',
                'application/pdf':'pdf'
            }
            const format = contentType_map[newFileUpload.type];
            formData.append("media", newFileUpload);
            const response = await api.post(`/upload_media?media_format=${format}`, formData);
            const file = {
                media_path: response.data.media_path,
                type: format
            };
            editFile(file);
        } catch (error) {
            console.log(error);
            alert("Erro ao realizar upload do arquivo!");
        }
        setLoading(false);
    };
    
    const editFile = async (file = {}) => {
        setLoading(true);
        try {
            const data = {
                ...file
            };
            await api.patch("/file/" + id, data);
            alert("Arquivo editado com sucesso");
        } catch (error) {
            console.log(error);
            alert("Erro ao editar arquivo!");
        }
        setLoading(false);
    }

    const handleEditLink = async () => {
        setLoading(true);
        try {
            var link = (newFileLink.replace("youtu.be/", "youtube.com/embed/").replace("watch?v=", "embed/")).slice(0, 41)
            const data = {
                type: newUploadType,
                media_path: link,                
            };
            await api.patch("/file/" + id, data);
            alert("Arquivo editado com sucesso");
        } catch (error) {
            console.log(error);
            alert("Erro ao editar arquivo!");
        }
        setLoading(false);
    }

return (
    <>
        <Header/>
        <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
        <Container>            
            <h1>{newTitle} </h1>
            <h2>{newDescription}</h2>
            <InputBox>
                <UploadSelection>
                    <h3>Upload do Arquivo:</h3>
                    <UploadOptions
                        value={newUploadType}
                        onChange={(event) =>
                        setNewUploadType(event.target.value)}
                    >
                    <option disabled={newUploadType}>Tipo de arquivo:</option>
                    <option value="upload">Upload de arquivo</option>
                    <option value="link">Link do arquivo</option>
                    </UploadOptions>
                    </UploadSelection>
                        {newUploadType === "link" ? (
                            <Input
                            value={newFileLink}
                            placeholder="Insira o link do vídeo"
                            onChange={(event) =>
                                setNewFileLink(event.target.value)
                            }
                            />
                            
                        ) : (
                            <MyDropzone
                            typeDocument={"*"}
                            setFile={setNewFileUpload}
                            />     
                        )}
            </InputBox>      
            {newUploadType === "link" ? (
                <EditButton
                    type="submit"
                    onClick={(event) => handleEditLink(event)}
                > 
                    {loading ? (<ReactLoading
                                    type="spin"
                                    color="#fffff"
                                    height={"20px"}
                                    width={"20px"}/>
                    ) : (<h3> Salvar alterações</h3>)}
                </EditButton> 
                ) : (
                <EditButton
                    type="submit"
                    onClick={(event) => handleEditUpload(event)}
                > 
                    {loading ? (<ReactLoading
                                    type="spin"
                                    color="#ffffff"
                                    height={"20px"}
                                    width={"20px"}/>
                    ) : (<h3> Salvar alterações</h3>)} 
                </EditButton> )}
        </Container>
        <Footer/>    
    </>
)
}