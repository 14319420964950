import React from 'react';
import { GlobalStyle } from './Styles/Global';
import { ThemeProvider } from 'styled-components';
import { theme } from './Styles/Theme';
import { AppRoutes } from './Routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <AppRoutes/>
    </ThemeProvider>
  );
}

export default App;