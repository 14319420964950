import styled from 'styled-components';

export const Foot = styled.div`
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 15px;
    h4{
        padding-top: 20px;
        padding-right: 30px;
    }
`