import styled from 'styled-components'
import { Link } from "react-router-dom"

export const ReturnButton = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 5vh;
    width: 100%;
    h2{
        color: ${props => props.theme.colors.branco};
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 2vw;
        padding-top: 1vh;
        gap: 1vw;
        cursor: pointer;
    }
`

export const Container = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
   
`

export const Titulo= styled.h3`
    color: ${props => props.theme.colors.azul};
    font-size: 1.5rem;
    margin-bottom: 2vh;
    text-align: center;
`

export const ColumnLeft= styled.div`
    background-color: ${props => props.theme.colors.azul};
    max-width: 35vw;
    display: flex;
    flex-direction: column;
    gap: 6vh;
    justify-content:center;
    margin: 15vh 7vw 15vh 5vw;
`

export const ColumnRight= styled.div`
    background-color: ${props => props.theme.colors.azul};
    max-width:65vw;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
    margin-right: 2vw;
`

export const InputBox = styled.div`
    background-color: none;
    width:30vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    h3{
        color: ${props => props.theme.colors.azul_claro};
        margin-right: 2vw;
    }
`

export const InputList = styled.select`
    background-color: ${(props) => props.theme.colors.branco};
    border: 3px solid gainsboro;
    height: 5vh;
    width: 20vw;
    border-radius: 5px;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
`;

export const DivInputs = styled.div`
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items:center;
`;

export const AdicionarButton = styled.button`
    background-color: ${props => props.theme.colors.azul};
    color: ${props => props.theme.colors.branco};
    box-shadow: #00668e 4px 4px 0px;
    border: none;
    border-radius: 10px;
    height: 5vh;
    width: 15vw;
    transition: transform 200ms, box-shadow 20ms;
    cursor: pointer;
    :active{
        transform: translateY(4px) translateX(4px);
        box-shadow: blue 0px 0px 0px;
    }
`
export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
`;


export const Botao = styled.button`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.colors.amarelo};
    color: ${props => props.theme.colors.azul};
    padding: 1vh 2vh;
    border: none;
    border-radius: 1vh;
    font-size: 1em;
    svg{
        font-size: 1.4em;
    }
    p{
        margin-right: 1vw;
    }
`
export const AreaPesquisa = styled.div`
    display: flex;
    flex-direction:row;
    gap: 1.5vh;
    align-items: center;
    justify-content: center;
    input{
        border: solid 0.4vh ${props => props.theme.colors.amarelo};
        padding: 1vw;
        color: ${props => props.theme.colors.azul};
        border-radius: 0.5vh;
        height: 5vh;
        width: 25vw;

    }
    input::placeholder {
        color: ${props => props.theme.colors.amarelo};
}
    textarea:focus, input:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
    }
`
export const FundoFiltro = styled.div`
    background-color: ${props => props.theme.colors.branco};
    border-radius: 2vh;
    padding: 4vh 5vw;
`

export const Tags = styled.div`
    height: 20vh;
    display: flex;
    flex-direction: row;
`

export const Direita = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 15vw;
    justify-content: center;
    flex-wrap: wrap;
    div{
        color: ${props => props.theme.colors.verde};
        font-weight: bold;
        font-size: 0.95em;
        margin-right: 0.7vw;
        text-align: right;
    }
    svg{
        color: red;
        font-size: 5vh;
    }   
`

export const Esquerda = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 20vw;
    height: 100%;
`

export const CadaResult = styled(Link)`
    display: flex;
    flex-direction: row;
    width:45vw;
    background-color: ${props => props.theme.colors.branco};
    padding: 2vh 3vw;
    margin-bottom: 2vh;
    margin-right: 15px;
    border-radius: 1vh;
    justify-content:space-between;
    overflow-wrap:break-word;
    flex-wrap:wrap;
`

export const ListaResults = styled.div`
    display:flex;
    flex-direction: column;
    height: 80vh;
    width: 50vw;
    margin: 3vh 0vw;
    overflow-y: scroll; 
    ::-webkit-scrollbar {
    width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.amarelo}}  
`

export const TituloResultado= styled.p`
    color: ${props => props.theme.colors.azul_claro};
    align-items: center;
    font-size: 1.35rem;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
`

export const Descricao = styled.p`
    color: ${props => props.theme.colors.azul};
    font-size: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
`

export const CadaTag = styled.div`
 display: flex;
 flex-direction: row;
 gap: 1vw;
 align-items: center;
 height: 5vh;
 background-color: ${props => props.theme.colors.branco};
 color: ${props => props.theme.colors.azul_claro};
 padding: 1vh 2vw;
 margin-bottom: 1vh;
 border-radius: 0.5vh;
 margin-right: 1vw;
 p{
    font-size: medium;
    font-weight: 600;
 }
 button{
    color: ${props => props.theme.colors.azul};
    font-size: larger;
    background: none;
    font-weight: bold;
    cursor: pointer;
 }
`

export const ListaTags = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
`
export const Tag = styled.p`
    font-size: 1.05em;
`

export const TypeFile = styled.div`

`