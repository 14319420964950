import styled from 'styled-components'
import { Link } from "react-router-dom"

export const Container = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 65vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: flex-start;
`

export const ColumnLeft= styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 65vh;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: flex-end;
    padding-bottom: 2vh;
`

export const ColumnRight= styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 65vh;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
    padding-right: 150px;
`
export const FileContainer = styled.div`
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

export const FilePreview = styled.div`
    background-color: ${props => props.theme.colors.azul};
    width: 320px;
    height: 180px;
    margin-bottom: 5px;
    img, video, object {
        width: 30vw;
        height: 30vh;
        ::-webkit-scrollbar { overflow-y: hidden; }
    }

    iframe {
        width: 30vw;
        height: 30vh;
    }

    h1{
        color: black;
    }
`

export const FileOptions = styled.div`
    background-color: ${props => props.theme.colors.azul};
    width: 30vw;
    height: 5vh;
    gap: 1vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 3vh;
`

export const FileButtons = styled.button`
    background-color: ${props => props.theme.colors.azul};
    display: flex;
    align-items: center;
    height: 4vh;
    gap: 0.5vw;
    border: 1px solid ${props => props.theme.colors.branco};
    border-radius: 5px;
    color: ${props => props.theme.colors.branco};
    padding-right: 0.5vw;
    h4{
        margin-left: 0.5vw;
        font-size: 1vw;
    }
    :hover{
        cursor: pointer;
        color: ${props => props.theme.colors.verde_claro};
        border: 1px solid ${props => props.theme.colors.verde_claro};
    }
`

export const InputBox = styled.div`
    background-color: ${props => props.theme.colors.azul};
    width:30vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    h3{
        color: ${props => props.theme.colors.branco};
    }
`

export const Bottom = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 10vh;
    width: 35vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2vw;
`

export const EditMediaBtn = styled(Link)` 
    background-color: ${props => props.theme.colors.amarelo};
    color: ${props => props.theme.colors.branco};
    box-shadow: ${props => props.theme.colors.amarelo_escuro} 4px 4px 0px;
    border: none;
    border-radius: 10px;
    height: 5vh;
    width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :active{
        transform: translateY(4px) translateX(4px);
        box-shadow: ${props => props.theme.colors.amarelo_escuro} 0px 0px 0px;
    }
`

export const EditButton = styled.button`
    background-color: ${props => props.theme.colors.verde};
    color: ${props => props.theme.colors.branco};
    box-shadow: ${props => props.theme.colors.verde_escuro} 4px 4px 0px;
    border: none;
    border-radius: 10px;
    height: 5vh;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :active{
        transform: translateY(4px) translateX(4px);
        box-shadow: ${props => props.theme.colors.verde_escuro} 0px 0px 0px;
    }
    :disabled{
        background: #505050;
        box-shadow: #505050 4px 4px 0px;
    }
`

export const DeleteButton = styled.button`
    background-color: ${props => props.theme.colors.vermelho};
    color: ${props => props.theme.colors.branco};
    box-shadow: ${props => props.theme.colors.vermelho_escuro} 4px 4px 0px;
    border: none;
    border-radius: 10px;
    height: 5vh;
    width: 170px;
    cursor: pointer;
    :active{
        transform: translateY(4px) translateX(4px);
        box-shadow: ${props => props.theme.colors.vermelho_escuro} 0px 0px 0px;
    }
`