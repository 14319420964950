import styled from 'styled-components'

export const Box = styled.div`
    background-color: ${props => props.theme.colors.branco};
    height: 55vh;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5vh;
    padding-bottom: 5vh;
    border-radius: 8px;
` 

export const Title = styled.div`
height: 8vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 5vh;
margin-bottom: 10vh;
h2{
    color: ${props => props.theme.colors.azul};
}
`