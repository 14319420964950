import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaArrowLeft } from 'react-icons/fa';
import { GoSearch } from 'react-icons/go'
import { AiFillEdit } from 'react-icons/ai'

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { ReturnButton } from "../AdicionarArquivo/styles";
import { Container } from "../EditarArquivo/styles"
import { Input, InputBox, Search, SearchIcon, List, Files, EditarBtn } from "../Administracao/styles";
import { Box, Title } from "./styles";
import api from "../../Services/api";

export function AlterarColaborador () {

    const [loading, setLoading] = useState(false);
    const [collaboratorsList, setCollaboratorsList ] = useState([]);
    const [search, setSearch] = useState("");

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/gerenciamento`)
    }

    useEffect(() => {
        const searchCollaborators = async () => {
            setLoading(true);
            try {
                const response = await api.get("/colaboradores");
                const collaborators = response.data.map((collaborator) => ({
                    id: collaborator.id,
                    name: collaborator.name
                }));
                setCollaboratorsList(collaborators);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        searchCollaborators();
    }, [])

    const filteredCollaborators = useMemo(() => {
        const lowersearch = search.toLowerCase();
        return collaboratorsList.filter((collaborator) => 
            collaborator.name.toLowerCase().includes(lowersearch));
    }, [search]);

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>
                <Box>
                    <Title> <h2>Edite um(a)</h2> 
                        <h2> colaborador(a)</h2> 
                    </Title>
                    <Search>
                        <InputBox>
                            <Input 
                                type="string"
                                placeholder="Procure por um colaborador"
                                value={search}
                                onChange={event => setSearch(event.target.value)}>
                            </Input>
                            <SearchIcon>
                                <h2><GoSearch/></h2>   
                            </SearchIcon>
                        </InputBox>
                        {!!search && (
                            <List>
                                {
                                    filteredCollaborators.map((colaborador) => {
                                        return(
                                            <Files to={`/editar-excluir-colaborador/${colaborador.id}`} state={colaborador}>
                                                <h2>{colaborador.name}</h2>
                                                <EditarBtn>
                                                    <AiFillEdit/>
                                                </EditarBtn>                                                
                                            </Files>
                                        )
                                    })
                                }
                            </List>)
                        }            
                    </Search> 
                </Box>
            </Container>
            <Footer/>
        </>
    )
}