import styled from 'styled-components';

export const Title = styled.div`
height: 8vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
margin-top: 2vh;
h2{
    color: ${props => props.theme.colors.azul};
}
`

export const Inputs = styled.div`
    height: 47vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 4vh;
    
`

export const Input = styled.input`
    background-color: ${props => props.theme.colors.branco};
    border-radius: 8px;
    border: 3px solid ${props => props.theme.colors.azul}; 
    height: 5vh;
    width: 230px;
    padding-left: 10px;
    cursor: text;
    textarea:focus, input:focus{
        box-shadow: 0 0 0 0;
        outline: 0;
    }
    ::placeholder{
        color: ${props => props.theme.colors.azul};
    }
`
export const InputSelect = styled.select`
    background-color: ${(props) => props.theme.colors.branco};
    color: ${(props) => props.theme.colors.azul};
    height: 5vh;
    width: 230px;
    border: 3px solid ${(props) => props.theme.colors.azul};
    border-radius: 8px;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
`;

export const AdicionarButton = styled.button`
    background-color: ${props => props.theme.colors.verde};
    width: 230px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    h4{
        color: ${props => props.theme.colors.branco};
    }
    cursor: pointer;
`