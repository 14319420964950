import React from "react";
import { Head, Logo } from "./styles";
import { MdOutlineLogin } from "react-icons/md";
import logo from "../../Assets/logo.svg";
import { useAuth } from "../../Hooks/useAuth";
import { NavLink } from "react-router-dom";

export function Header() {
    // Só aparecer caso a pessoa esteja logada

    const { token } = useAuth();

    return (
        <Head>
            <Logo to="/">
                <img src={logo} alt="logo" />
            </Logo>
            <h1>Repositório ETARSERRA</h1>
            <div>
                {token ? (
                    <NavLink to={"/admin"}>
                        <button>
                            <h3> Admin </h3>
                            <h1>
                                <MdOutlineLogin />
                            </h1>
                        </button>
                    </NavLink>
                ) : (
                    ""
                )}
            </div>
        </Head>
    );
}
