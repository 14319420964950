import React, {useState} from 'react'
import { useNavigate, useParams } from "react-router-dom";

//Styles
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../Styles/Global'
import {theme} from '../../Styles/Theme'
import { Botao } from '../Login/styles'
import { Background, Container } from './styles';

//Componentes
import { Header } from "../../Components/Header";
import {Footer} from "../../Components/Footer";

import api from '../../Services/api';


export default function RedefinirSenha() {

    const [novaSenha, setNovaSenha] = useState("");
    const [confirmSenha, setConfirmSenha] = useState("");

    const navigate = useNavigate()
    
    const {id} = useParams();

    const handleSubmit = async () => {  //Função do Login, ela é assíncrona
        try { 
            if(novaSenha === confirmSenha){
                const data = {
                    password: novaSenha,
                };
                await api.patch(`/esquecisenha/novasenha/${id}`, data);
                alert(`Sua senha foi redefinida com sucesso!`)
                navigate("/login")
            } else {
                alert("As senhas não coincidem!")
            }
        } catch (error) {   
            console.log("erro")
            alert(`Não foi possível redefinir a senha!`)
        }
      };


    return(
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Header />
                <Background>
                    <Container>
                        <h1>Redefinir sua senha</h1>
                        <p>Preencha os campos abaixo</p>
                        <input 
                        placeholder='Digite sua nova senha'
                        type="password"
                        value={novaSenha} 
                        onChange={(e) => setNovaSenha(e.target.value)}/>
                        <input 
                        placeholder='Confirme sua nova senha'
                        type="password"
                        value={confirmSenha}
                        onChange={(e) => setConfirmSenha(e.target.value)}/>
                        <Botao onClick={handleSubmit}>REDEFINIR</Botao>
                        <a href = "/login">Cancelar</a>
                    </Container>
                </Background>

                <Footer />
            </ThemeProvider>
        </>
    )
}