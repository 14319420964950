import React, {useState, useRef, useEffect} from 'react';

import { AiFillFile, AiFillPicture } from "react-icons/ai"
import { BsFillCameraVideoFill } from "react-icons/bs"
import { AiOutlineLink } from "react-icons/ai";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import { Arquivos, Background, Container, DivArquivos, Info, NavButton, Titulo } from './styles'
import api from '../../Services/api';

function CarrosselAccessed() {

    const [filesList, setFilesList] = useState([]);

    const carousel = useRef(null);

    const prev = (e) => {
        e.preventDefault();
        carousel.current.scrollLeft -= carousel.current.offsetWidth;
    };
    
    const next = (e) => {
        e.preventDefault();
    
        carousel.current.scrollLeft += carousel.current.offsetWidth;
    };

    useEffect(() => {
        const getTopAccessFiles = async () => {
            try {
                const response = await api.get("/file/top-access");
                const files = response.data.map((file) => ({
                    id: file.id,
                    title: file.title,
                    category: file.categories_associated,
                    area: file.areas_associated,
                    year: file.year,
                    awarded: file.awarded,
                    description: file.description,
                    tags: file.tags_associated,
                    authors: file.authors_associated,
                    click_quantity: file.click_quantity,
                    creator_id: file.creator_id,
                    media_path: file.media_path,
                    type: file.type,
                }));
                setFilesList(files);
            } catch (error) {
                console.log(error);
            }
        };
        getTopAccessFiles();
    }, [])

  return (
    <Background>
        <NavButton left onClick={prev}> 
            <IoIosArrowBack />
        </NavButton>
        <Container ref={carousel}>
            <DivArquivos>
                {
                    filesList.map((file, index) => {
                        return(
                            <Arquivos key = {index} to={`/arquivo/${file.id}`} state={file}>
                                <Titulo> <p>{file.title}</p> </Titulo>
                                <Info>
                                    <p>{file.category[0].name}</p>
                                    {file.type === "png" ? (
                                        <AiFillPicture/>
                                        ) : (file.type === "jpeg" ? (
                                            <AiFillPicture/>
                                        ) : (file.type === "jpg" ? (
                                            <AiFillPicture/>
                                        ) : (file.type === "gif" ? (
                                            <AiFillPicture/>
                                        ) : (file.type === "mp3" ? (
                                            <BsFillCameraVideoFill/>
                                        ) : (file.type === "mp4" ? (
                                            <BsFillCameraVideoFill/>
                                        ) : (file.type === "link" ? (
                                            <AiOutlineLink/>
                                        ) : (
                                            <AiFillFile/>
                                        )))))))}
                            </Info>
                            </Arquivos>
                        )
                    })

                }
            </DivArquivos>
        </Container>
        <NavButton right onClick={next}> 
            <IoIosArrowForward />
        </NavButton>
    </Background>
  )
}

export default CarrosselAccessed