import styled from 'styled-components'
import { Link } from "react-router-dom"

export const Buttons = styled.div`
    width: 25vw;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`

export const EditarFotosBtn = styled(Link)`
    background-color: ${props => props.theme.colors.azul};
    width: 100vw;
    max-width: 250px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    h5{
        color: ${props => props.theme.colors.branco};
    }
`

export const AddColabBtn = styled(Link)`
    background-color: ${props => props.theme.colors.verde};
    width: 100vw;
    max-width: 250px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    h5{
        color: ${props => props.theme.colors.branco};
    }
`

export const EditDeletColabBtn = styled(Link)`
    background-color: ${props => props.theme.colors.amarelo};
    width: 100vw;
    max-width: 250px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    h5{
        color: ${props => props.theme.colors.branco};
    }
`