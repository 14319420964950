import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { DropContainer, UploadMessage } from "./styles";

export function BannerDropzone({ setBanner }) {

    const [tempBanner, setTempBanner] = useState(null);

    function renderDragMessage(isDragActive, isDragReject) {
        if (isDragReject) {
            return (
                <UploadMessage type="error">
                    Arquivo não suportado
                </UploadMessage>
            );
        }
        if (tempBanner) {
            return (
                <UploadMessage type="accepted">{tempBanner.name}</UploadMessage>
            );
        }
        if (!isDragActive) {
            return <UploadMessage>Fazer upload de uma nova imagem</UploadMessage>
        }
        return (
            <UploadMessage type="sucess">
                Solte os arquivos aqui.
            </UploadMessage>
        );
    }

    useEffect(() => {
        setBanner(tempBanner);
    }, [setBanner, tempBanner]);

    return(
        <Dropzone 
            accept = {{
                'image/png': ['.png', '.jpeg', '.jpg'],
                'image/jpeg': ['.png', '.jpeg', '.jpg'],
                'image/jpg': ['.png', '.jpeg', '.jpg'],
            }}
            value={tempBanner}
            onDropAccepted={(acceptedBanners) => setTempBanner(acceptedBanners[0])}
        >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <DropContainer
                    {...getRootProps()}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    tempBanner={tempBanner}
                >
                    <input {...getInputProps()} />
                    {renderDragMessage(isDragActive, isDragReject)}
                </DropContainer>
            )}
        </Dropzone>
    );
}