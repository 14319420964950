import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Modal from 'react-modal';

import { FaArrowLeft } from 'react-icons/fa';
import ReactLoading from 'react-loading';

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { ReturnButton } from "../AdicionarArquivo/styles"
import { Container } from "../EditarArquivo/styles"
import { Box } from "../Administracao/styles";
import { Title, Inputs, InputSelect, Input } from "../AdicionarColaborador/styles";
import { Buttons, PasswordBtn, EditBtn, DeleteBtn, ModalContainer } from "./styles";
import api from "../../Services/api";

export function AlterarColaborador2 () {

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/editar-excluir-colaborador`)
    }

    const { id } = useParams();
    const [newIsAdmin, setNewIsAdmin] = useState(false);
    const [newName, setNewName] = useState("");
    const [newEmail, setNewEmail] = useState("");

    const [loading, setLoading] = useState(false);
    const [invalidFields, setInvalidFields] = useState(false);

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = { //Costumização do Modal
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '${props => props.theme.colors.branco}',
          borderRadius: '2vh',
          padding: '4vh 5vw',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        }
      };
        Modal.setAppElement('#root');

    useEffect(() => {
        const getUser =  async () => {
            setLoading(true);
            try {
                const response = await api.get("/colaborador/detalhes/" + id);
                console.log(response.data)
                setNewIsAdmin(response.data.isAdmin);
                setNewName(response.data.name);
                setNewEmail(response.data.email);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        getUser();
    }, []);

    const handleEdit = async () => {
        setLoading(true);
        try {
            const data = {
                isAdmin: newIsAdmin,
                name: newName,
                email: newEmail,
            };
            console.log(data);
            const response = await api.patch("/colaborador/detalhes/" + id, data);
            console.log(response.data);
            alert("Colaborador(a) editad(o) com sucesso!");
        } catch (error) {
            console.log(error);
            alert("Erro ao editar colaborador(a)!");
        }
        setLoading(false);
    }

    const handleDelete = async () => {
        setLoading(true);
        try {
            await api.delete("/colaborador/detalhes/" + id);
            handleNavigate()
        } catch (error) {
            console.log(error);  
            alert("Erro ao deletar colaborador(a)!");
        }
        setLoading(false);
    }
    
    useEffect(() => {
        if ( !newName || !newEmail 
        )
            {setInvalidFields(true);
        }
        else{
            setInvalidFields(false);
        }
    }, [
        newName, newEmail
    ]);

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>
                <Box>
                    <Title> <h2>Edite um(a)</h2> 
                        <h2> colaborador(a)</h2> 
                    </Title>
                    <Inputs>
                        <InputSelect
                            value={newIsAdmin}
                            onChange={(event) =>
                                setNewIsAdmin(event.target.value)}
                        >
                            <option disabled={true}>Tipo de usuário</option>
                            <option value={false}>Colaborador</option>
                            <option value={true}>Administrador</option>
                        </InputSelect>

                        <Input 
                            type="text"
                            placeholder="Novo nome"
                            value={newName}
                            onChange={event => setNewName(event.target.value)}>
                        </Input>

                        <Input 
                            type="text"
                            placeholder="Novo email"
                            value={newEmail}
                            onChange={event => setNewEmail(event.target.value)}>
                        </Input>

                        <PasswordBtn to={`/editar-senha-colaborador/` + id}> <h4>Redefinir senha</h4></PasswordBtn>

                        <Buttons>
                            <EditBtn onClick={handleEdit} disabled={invalidFields}> 
                            {loading ? (<ReactLoading
                                    type="spin"
                                    color="#ffffff"
                                    height={"20px"}
                                    width={"20px"}/>
                            ) : (<h4>EDITAR</h4>)} 
                            </EditBtn>
                            <DeleteBtn onClick={openModal}> <h4>EXCLUIR</h4> </DeleteBtn>
                        </Buttons> 
                        
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <ModalContainer>
                                <Title>Tem certeza que deseja excluir esse colaborador(a)?</Title>
                                <Buttons>
                                    <EditBtn onClick={closeModal}> <h4>CANCELAR</h4> </EditBtn>
                                    <DeleteBtn onClick={handleDelete}> <h4>EXCLUIR</h4> </DeleteBtn>
                                </Buttons>
                            </ModalContainer>
                        </Modal>                       
                    </Inputs>
                </Box>
            </Container>
        <Footer/>
    </>
       
    )
}