import styled, { css } from "styled-components";

export const Container = styled.div`
    height: 100%;
    width: 100%;
    margin-right: 15px;
`

const DragActive = css`
    border-color: ${(props) => props.theme.colors.verde};
`;

const DragReject = css`
    border-color: red;
`;

const BannerAccepted = css`
    border-color: ${(props) => props.theme.colors.verde};
`;

export const DropContainer = styled.div.attrs({
    className: "dropzone",
})`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.colors.branco};
    color: ${props => props.theme.colors.azul};
    border: 1px dashed #0490C9;
    border-radius: 4px;
    cursor: pointer;
    transition: height 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.isDragActive && DragActive};
    ${(props) => props.isDragReject && DragReject};
    ${(props) => props.tempBanner && BannerAccepted};
`;

const messageColors = {
    default: "#0490C9",
    error: "#E42929",
    success: "#004181",
    accepted: "#32CD32",
};

export const UploadMessage = styled.p`
    display: flex;
    max-width: 100%;
    color: ${(props) => messageColors[props.type || "default"]};
    justify-content: center;
    align-items: center;
    padding: 15px;
    text-align: center;
    word-break: break-all;
`;
