import styled from 'styled-components' 
import { Link } from "react-router-dom"

export const Buttons = styled.div`
    width: 230px;
    display: flex;
    justify-content: space-between;
`

export const PasswordBtn = styled(Link)`
    background-color: ${props => props.theme.colors.azul};
    width: 230px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    h4{
        color: ${props => props.theme.colors.branco};
    }
`

export const EditBtn = styled.button` 
    background-color: ${props => props.theme.colors.amarelo};
    width: 100px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    h4{
        color: ${props => props.theme.colors.branco};
    }
`

export const DeleteBtn = styled.button` 
    background-color: ${props => props.theme.colors.vermelho};
    width: 100px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    h4{
        color: ${props => props.theme.colors.branco};
    }
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`