import styled from 'styled-components'

export const Container = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 65vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
    h1{
        color: ${props => props.theme.colors.branco};
    }
    h2{
        color: ${props => props.theme.colors.branco};
        margin-bottom: 10vh;
    }
`

export const InputBox = styled.div`
    background-color: ${props => props.theme.colors.azul};
    width: 35vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    h3{
        color: ${props => props.theme.colors.branco};
        margin-right: 2vw;
    }
    margin-bottom: 10vh;
`

export const Input = styled.input`
    background-color: ${props => props.theme.colors.branco};
    border-radius: 8px;
    height: 5vh;
    width: 30vw;
    padding-left: 10px;
    cursor: text;
    textarea:focus, input:focus{
        box-shadow: 0 0 0 0;
        outline: 0;
    }
    ::placeholder{
        color: ${props => props.theme.colors.azul};
    }
`

export const UploadSelection = styled.div`
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const UploadOptions = styled.select`
    background-color: ${(props) => props.theme.colors.azul_2};
    height: 4vh;
    width: 15vw;
    border-radius: 5px;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1vh;
`