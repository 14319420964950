import React, {useState, useEffect } from "react";
import Dropzone  from "react-dropzone";
import { DropContainer, UploadMessage } from "./styles";

export function MyDropzone({ setFile, disabled }) {
    
    const [tempFile, setTempFile] = useState(null);

    function renderDragMessage(isDragActive, isDragReject) {
        if (isDragReject) {
            return (
                <UploadMessage type="error">
                    Arquivo não suportado
                </UploadMessage>
            );
        }
        if (tempFile) {
            return (
                <UploadMessage type="accepted">{tempFile.name}</UploadMessage>
            );
        }
        if (!isDragActive) {
            return (
                <UploadMessage>
                    Arraste seu arquivo aqui...
                </UploadMessage>
            );
        }
        return (
            <UploadMessage type="sucess">
                Solte os arquivos aqui.
            </UploadMessage>
        );
    }

    useEffect(() => {
        setFile(tempFile);
    }, [setFile, tempFile]);

    return (
        <Dropzone
            value={tempFile}
            onDropAccepted={(acceptedFiles) => setTempFile(acceptedFiles[0])}
            disabled={disabled}
        >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                <DropContainer
                    {...getRootProps()}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    tempFile={tempFile}
                >
                    <input {...getInputProps()} />
                    {renderDragMessage(isDragActive, isDragReject)}
                </DropContainer>
            )}
        </Dropzone>
    );
}