import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

import ReactLoading from "react-loading";

export function TagsSelector({ setTags, tagsOptions, loading, onCreateOption }) {

    const [selectedOption, setSelectedOption] = useState([]);

    const { Option } = components;
    const TagsOption = (props) => 
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

    useEffect(() => {
        setTags(selectedOption);
    }, [setTags, selectedOption]);
    

    const styles = {
        container: base => ({
            ...base,
            width: "20vw"
        })
    };

    return (
        <CreatableSelect
            styles={styles}
            isClearable
            options={tagsOptions}
            components={{ Option: TagsOption}}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e)}
            isMulti={true}
            placeholder="Selecione a(s) tag(s)"
            closeMenuOnSelect={false}
            onCreateOption = {onCreateOption}
        />
    );
}