import styled from 'styled-components' 
import { Link } from "react-router-dom"

export const Container = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 75%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: flex-start;
    h1{
        color: ${props => props.theme.colors.branco};
        margin-top: 3vh;
        margin-right: 2vw;
    }
    
    h3{
        color: ${props => props.theme.colors.branco};
        margin-top: 2vh;
        margin-right: 2vw;
        display: flex;
    }

    p{
        color: ${props => props.theme.colors.branco};
        margin-left: 0.5vw;
        margin-right: 2vw;
        font-size: 18px;
    }
`

export const ColumnLeft = styled.div`
    background-color: ${props => props.theme.colors.azul};
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    gap: 5vh;
    margin-top: 10px;
`

export const ColumnCenter = styled.div`
    min-width: 25vw;
    max-width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
`

export const ColumnRight = styled.div`
    background-color: ${props => props.theme.colors.azul};
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

export const InfosList = styled.div`
    background-color: ${props => props.theme.colors.branco};
    height: 5vh;
    min-width: 10vw;
    max-width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 1vh;
    margin-top: 1vh;
    color: ${props => props.theme.colors.azul};
    font-size: 1vw;
`

export const List = styled.ul`
    background-color: ${props => props.theme.colors.azul};
    width: 100%;
    min-height: 55vh;
    max-height: 55vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1vw;
    margin-top: 2vh;
    overflow-y: scroll; 
    ::-webkit-scrollbar {
    width: 15px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.amarelo};
        border-radius: 15px;
    }
`

export const Files = styled(Link)`
    background-color: ${props => props.theme.colors.branco};
    width: 100%;
    min-height: 15vh;
    max-height: 15vh;
    font-size: small;
    color: ${props => props.theme.colors.azul};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1vw;
    padding: 1vw;
    font-size: 1vw;
`

export const Info = styled.div`
    width: 70%;
    p{
        color: ${props => props.theme.colors.verde};
        margin-top: 0vw;
        margin-top: 10px;
        font-size: 18px;
        font-weight: bold;
    }
    h2{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
    
`

export const  TypeFile = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg{
        color: red;
        font-size: 6vh;
    }
`

export const FileContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 25px;
`

export const DescContainer = styled.div`
    width: 100%;
`

export const FileTitle = styled.h3`
    color: white;
`