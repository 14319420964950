import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

import ReactLoading from "react-loading";

export function AutoresSelector({ setAuthors, authorsOptions, loading, onCreateOption }) {

    const [selectedOption, setSelectedOption] = useState([]);
       
    const { Option } = components;
    const AuthorsOption = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

        useEffect(() => {
            setAuthors(selectedOption);
        }, [setAuthors, selectedOption])

    const styles = {
        container: base => ({
            ...base,
            width: "20vw"
        })
    };

    return (
        <CreatableSelect
            styles={styles}
            isClearable
            options={authorsOptions}
            components={{ Option: AuthorsOption }}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e)}
            isMulti={true}
            placeholder="Selecione o(s) autor(es)"
            closeMenuOnSelect={false}
            onCreateOption = {onCreateOption}
        />
    );
}