import React, { useState, useEffect } from "react";

import { Date_Picker } from "./styles";

export function MyDatePicker({ setYear, initialValue }) {

    const [ tempYear, setTempYear] = useState(initialValue);

    useEffect(() => {
        setYear(tempYear);
    }, [setYear, tempYear]);

    return (
        <Date_Picker
            value={tempYear}
            onChange={(event) => setTempYear(event)}   
            maxDetail="decade"
            maxDate={new Date()}
        />    
    )
}