import styled from 'styled-components';
import Select from "react-select";

export const ReturnButton = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 5%;
    width: 100%;
    h2{
        color: ${props => props.theme.colors.branco};
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 2vw;
        padding-top: 1vh;
        gap: 1vw;
        cursor: pointer;
    }
`

export const Container = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: flex-start;
`

export const ColumnLeft= styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 100%;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: flex-end;
    margin-left: 5vw;
`

export const ColumnRight= styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 100%;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: flex-start;
    margin-right: 10vw;
`

export const InputBox = styled.div`
    background-color: ${props => props.theme.colors.azul};
    width:35vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    h3{
        color: ${props => props.theme.colors.branco};
        margin-right: 2vw;
    }
`

export const Input = styled.input`
    background-color: ${props => props.theme.colors.branco};
    border-radius: 8px;
    height: 5vh;
    width: 20vw;
    padding-left: 10px;
    cursor: text;
    textarea:focus, input:focus{
        box-shadow: 0 0 0 0;
        outline: 0;
    }
    ::placeholder{
        color: ${props => props.theme.colors.azul};
    }
`

export const InputDescription = styled.textarea`
    resize: none;
    background-color: ${props => props.theme.colors.branco};
    border-radius: 8px;
    height: 15vh;
    width: 20vw;
    padding-left: 10px;
    padding-top: 10px;
    cursor: text;
    ::placeholder{
        color: ${props => props.theme.colors.blue};
    }
`

export const UploadSelection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
`

export const SelectDropdown = styled(Select)`
    width: 50vw;
`;

export const UploadOptions = styled.select`
    background-color: ${(props) => props.theme.colors.azul_2};
    height: 4vh;
    width: 11vw;
    border-radius: 5px;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1vh;
    margin-right: 25px;
`

export const InputList = styled.select`
    background-color: ${(props) => props.theme.colors.branco};
    height: 5vh;
    width: 20vw;
    border-radius: 5px;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
`;

export const Bottom = styled.div`
    background-color: ${props => props.theme.colors.azul};
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    `

export const AdicionarButton = styled.button`
    background-color: ${props => props.theme.colors.verde};
    color: ${props => props.theme.colors.branco};
    box-shadow: green 4px 4px 0px;
    border: none;
    border-radius: 10px;
    height: 5vh;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 200ms, box-shadow 20ms;
    cursor: pointer;
    :active{
        transform: translateY(4px) translateX(4px);
        box-shadow: green 0px 0px 0px;
    }
    :disabled{
        background: #505050;
        box-shadow: #505050 4px 4px 0px;
    }
`