import styled from 'styled-components'

export const Background = styled.div`
    padding: 5vh;
    display: flex;
    flex-direction: column;
    height: 75vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.azul};
`
export const Container = styled.div`
    padding: 5vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 65vh;
    width: 100%;
    max-width: 350px;
    border-radius: 32px;
    background-color: ${props => props.theme.colors.branco};
    h1{
        color: ${props => props.theme.colors.azul};
        font-size: 4vh;
        margin-bottom: 5vh;
        font-style: normal;
        font-weight: 700;
    }
    input{
        border: solid 0.5vh ${props => props.theme.colors.azul};
        padding: 1vw;
        color: ${props => props.theme.colors.azul};
        font-size: 1em;
        border-radius: 1vh;
        height: 5.5vh;
        width: 100%;
        max-width: 350px;
        margin-top: 5vh;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    a{
        color: ${props => props.theme.colors.azul};
        text-decoration: underline;
        font-size: 2.7vh;
        margin-top: 2vh;
        font-weight: 500;
    }
    p{
        color: ${props => props.theme.colors.azul};
        font-weight: 400;
        text-align: justify;
        font-size: 2.5vh;
    }
    input::placeholder {
        color: ${props => props.theme.colors.azul};
}
    textarea:focus, input:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
    }
`

export const Botao = styled.button`
    background-color: ${props => props.theme.colors.verde};
    color: ${props => props.theme.colors.branco};
    height: 5.5vh;
    width: 100%;
    max-width: 350px;
    border: none;
    border-radius: 2vh;
    margin-top: 6vh;
    font-size: 2vh;
`