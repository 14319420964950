import styled, {css} from 'styled-components'
import { Link } from "react-router-dom"

export const Background = styled.div`
    margin: 2vh 0vw;
    //position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Container = styled.div`
    width: 80vw;
    margin: 0vh 2vw;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
        display: none;
    }
    
`
export const Arquivos = styled(Link)`
    min-width: 15vw;
    max-width: 15vw;
    height: 17vh;
    padding: 2vh 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    background-color: #fcf6ef;
    box-shadow: 0 0 10px gray;
    cursor: pointer;
`; 

export const Titulo = styled.p`
   color: ${props => props.theme.colors.azul_claro};
   align-items: center;
   font-size: 1.05em;
   font-weight: bold;
   display: flex;
   align-items: flex-start;
   overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
`
export const Info = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //gap: 6vw;
    p{
        color: ${props => props.theme.colors.verde};
        font-weight: bold;
        font-size: 0.85em;
    }
    svg{
        color: red;
        font-size: 4vh;
    }
`;

export const DivArquivos = styled.div`
    gap: 2vw;
    display:flex;
    flex-wrap:row;
`; 

export const NavButton = styled.button`
    width: 3vw;
    height: 6vh;
    border-radius: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vh;
    outline: none;
    border: none;
    background: white;
    cursor: pointer;
    padding: 0;
    //position: absolute;
    top: 35%;
    color:  ${props => props.theme.colors.azul};
    //box-shadow: 0px 6px 60px 2px rgba(3,3,3,0.9), inset 0 --3em 3em rgba(3,3,3,0.5);
    box-shadow: 0px 6px 60px 0.5px rgba(3,3,3,0.5);
    ${(props) => props.right === true ? css`right: 2%` : css`left: 2%`};
`