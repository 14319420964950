import React, {useState, useEffect} from 'react'
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";

//Styles
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../Styles/Global'
import {theme} from '../../Styles/Theme'
import { AreaSenha, Background, Botao, Container } from './styles';

import { Header } from "../../Components/Header";
import {Footer} from "../../Components/Footer";
import { useAuth } from '../../Hooks/useAuth'

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [invalidFields, setInvalidFields] = useState(false);
    const { login, token } = useAuth();

    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");

    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            navigate("/admin");
        }
    }, [token,navigate]);

;
    const handleSubmit = async () => {  
        const data = {
            email: email,
            password: senha,
        };
        login(data, setLoading, setLoginError, navigate);
      };

      useEffect(() => {
        if (!email || !senha) {
            setInvalidFields(true);
        } else {
            setInvalidFields(false);
        }
    }, [email, senha]);

    return(
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Header />
                <Background>
                    <Container>
                        <h1>Faça seu login</h1>
                        <input 
                        placeholder='Email'
                        value={email} /*O conteudo do imput*/
                        onChange={(e) => setEmail(e.target.value)}/>
                        <AreaSenha>
                            <input  type = 'password' placeholder='Senha'
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}/>
                            <a href="/esqueci-senha">Esqueci minha senha</a>
                        </AreaSenha>
                        {loading ? (
                            <ReactLoading
                                type="spin"
                                color="#0490C9"
                                height={"50px"}
                                width={"50px"}/>
                        ) : (
                        <Botao onClick={handleSubmit} disabled={invalidFields}>CONTINUAR</Botao>
                        )}
                    </Container>
                </Background>
                <Footer />
            </ThemeProvider>
        </>
    )
}