import { createGlobalStyle } from "styled-components";
import 'react-circular-progressbar/dist/styles.css'

export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        box-sizing: border-box;
        box-shadow: 0 0 0 0;
        outline: 0;
        border: none;
        text-decoration: none;
    }
    
    li{
        text-decoration: none;
        list-style: none;
    }
    `