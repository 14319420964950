import React, {useState, useEffect } from 'react';
import Carousel from 'better-react-carousel';

import { Container, ImageContainer} from './styles';
import api from "../../Services/api"

function Slides() {
  const [uploadedBanners, setUploadedBanners] = useState([]);
  const [loading, setLoading] = useState(false);

const getBanners = async() => {
  setLoading(true);
  try {
      const response = await api.get("/banner");
      const banners =  response.data.map((banner) => ({
          bannerUrl: "https://etarserra.fly.dev/media/" + banner.media_path,
          bannerName: banner.banner_name
      }))
      setUploadedBanners(banners)
  } catch (error) {
      console.log(error)
  } 
  setLoading(false);
  };

  useEffect(() => {
    getBanners();
    console.log(uploadedBanners);
  }, [])

  return (
      <>
        <Container>
          {uploadedBanners.length < 1 || loading ? (
            <Carousel gap={30} loop autoplay={10000} showDots>
              <Carousel.Item>
                <ImageContainer/>
              </Carousel.Item>
            </Carousel>
          ) : (
            <Carousel gap={50} loop autoplay={10000} showDots>
              {uploadedBanners.map((banner) => {
                return(
                  <Carousel.Item>
                    <ImageContainer src={banner.bannerUrl} alt={banner.bannerName} />
                  </Carousel.Item>
                )
              })}
            </Carousel>
          )}
        </Container>
      </>
  )
}

export default Slides