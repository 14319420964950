import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";

import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../Styles/Global'
import {theme} from '../../Styles/Theme'

import { IoSearchSharp } from "react-icons/io5";
import { MdTune } from "react-icons/md";

import { Header } from "../../Components/Header";
import {Footer} from "../../Components/Footer";
import { AreaPesquisa, AdicionarButton, Background, Botao, BotaoSair, Container, Destaques, DivBotao, Divisao, Titulo, InputBox, InputList, DivInputs, Div} from './styles';
import { MyDatePicker } from "../../Components/MyDatePicker";
import ReactLoading from "react-loading";
import Select, { components } from "react-select";
import Slides from '../../Components/Slides';
import CarrosselAccessed from '../../Components/CarrosselAccessed';
import CarrosselAward from '../../Components/CarrosselAward';
import Modal from 'react-modal';
import api from "../../Services/api"

const customStyles = { //Costumização do Modal
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      //height: '65vh',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '${props => props.theme.colors.branco}',
      borderRadius: '2vh',
      padding: '4vh 5vw',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 99999,
    }
  };
    Modal.setAppElement('#root');

export default function Home() {
    //Cria o modal
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    //Os Inputs
    const [categoryChecked, setCategoryChecked] = useState("");
    const [areaChecked, setAreaChecked] = useState("");
    const [year, setYear] = useState("");
    const [award, setAward] = useState("");
    const [authorsChecked, setAuthorsChecked] = useState([]);
    const [tagsChecked, setTagsChecked] = useState([]);
    const [title, setTitle] = useState([]);
    
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [areasOptions, setAreasOptions] = useState([]);
    const [authorsOptions, setAuthorsOptions] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);

    const { Option } = components;
    const ListOptions = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

    const styles = {
        container: base => ({
            ...base,
            width: "20vw"
        })
    };

    // ---------------------- Faz requisições de busca e criação de Categorias --------------------------------

    const searchCategory = async () => {
        setLoading(true);
        try {
            const response = await api.get("/category");      //Colocar rota do back
            const categoriesOptions = response.data.map((category) => ({
                id: category.id,
                value: category.name,
                label: category.name,
            }));
            setCategoriesOptions(categoriesOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchCategory();
    }, [categoryChecked]);  
    
    // ---------------------- Faz requisições de busca e criação de Áreas --------------------------------

    const searchArea = async () => {
        setLoading(true);
        try {
            const response = await api.get("/area");      //Colocar rota do back
            const areasOptions = response.data.map((area) => ({
                id: area.id,
                value: area.name,
                label: area.name,
            }));
            setAreasOptions(areasOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchArea();
    }, [areaChecked]);       

    // ---------------------- Faz requisições de busca e criação de Autores --------------------------------

    const searchAuthor = async () => {
        setLoading(true);
        try {
            const response = await api.get("/autores");
            const authorOptions = response.data.map((author) => ({
                id: author.id,
                value: author.name,
                label: author.name,
            }));
            setAuthorsOptions(authorOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchAuthor();
    }, [authorsChecked]);  

    // ---------------------- Faz requisições de busca e criação de Tags --------------------------------

    const searchTags = async () => {
        setLoading(true);
        try {
            const response = await api.get("/tags");
            const tagOptions = response.data.map((tag) => ({
                id: tag.id,
                value: tag.name,
                label: tag.name,
            }));
            setTagsOptions(tagOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchTags();
    }, [tagsChecked]);

    // ---------------------- Faz requisições de pesquisa --------------------------------

    const handlePesquisa = async (file = {}) => {
        setLoading(true);
        try {
            var date = (String(year)).slice(11, 15)
            var categoryList = categoryChecked.id ? `/category=[${categoryChecked.id}]` : `/category=`;
            var areaList = areaChecked.id ? `/area=[${areaChecked.id}]` : `/area=`;
            var autores = authorsChecked.map((author) => author.id)
            var authorsList = autores.length > 0 ? `/authors=[${autores}]` : `/authors=`;
            var tag = tagsChecked.map((tag) => tag.id)
            var tagsList = tag.length > 0 ? `/tags=[${tag}]` : `/tags=`;            
            const stringRoute = `awarded=${award}/min_year=${date}/max_year=${date}` + categoryList + areaList + tagsList + authorsList;
            console.log(stringRoute)
            navigate(`/resultados-filtro/${stringRoute}`)
        } catch (error) {
            console.log(error);
            alert("Erro ao pesquisar!");
        }
        setLoading(false);
    }

    const handlePesquisaTitulo = async (file = {}) => {
        setLoading(true);
        try {
            const stringRoute = `title=${title}`;
            console.log(stringRoute)
            navigate(`/resultados-titulo/${stringRoute}`)
        } catch (error) {
            console.log(error);
            alert("Erro ao pesquisar!");
        }
        setLoading(false);
    }

    return(
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Header />
                <Background>
                    <Divisao>
                        <Container>
                            <Botao onClick={openModal}>
                                <p>Filtros </p>
                                < MdTune/>
                            </Botao>

                            { /* Essa parte do modal só vai aparecer quando for chamada pelo Botão de filtros */ }
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >
                                <DivBotao>
                                    <BotaoSair onClick={closeModal}>X</BotaoSair>
                                </DivBotao>

                                <Titulo>Selecione as opções </Titulo>
                                    
                                <DivInputs>
                                    <InputBox>
                                        <h3>Categoria:</h3>
                                        <Select
                                            styles={styles}
                                            isClearable
                                            options={categoriesOptions}
                                            components={{ Option: ListOptions }}
                                            value={categoryChecked}
                                            onChange={(e) => setCategoryChecked(e)}
                                            placeholder="Selecione a categoria"
                                        />
                                    </InputBox>  

                                    <InputBox>
                                        <h3>Área:</h3>
                                        <Select
                                            styles={styles}
                                            isClearable
                                            options={areasOptions}
                                            components={{ Option: ListOptions }}
                                            value={areaChecked}
                                            onChange={(e) => setAreaChecked(e)}
                                            placeholder="Selecione a área"
                                        />    
                                    </InputBox>

                                    <InputBox>
                                        <h3>Ano:</h3>
                                        <MyDatePicker
                                            setYear={setYear}
                                        />                                      
                                    </InputBox>  
                                
                                    <InputBox>
                                        <h3>Premiação:</h3>
                                        <InputList
                                            value={award}
                                            onChange={(event) =>
                                                setAward(event.target.value)}
                                            >
                                            <option value = "">Nenhum</option>
                                            <option value="Sim">Sim</option>
                                            <option value="Não">Não</option>
                                            <option value="Não informado">Não informado</option>
                                        </InputList>               
                                    </InputBox> 

                                    <InputBox>
                                        <h3>Autor:</h3>
                                        <Select
                                            styles={styles}
                                            isClearable
                                            options={authorsOptions}
                                            components={{ Option: ListOptions}}
                                            value={authorsChecked}
                                            onChange={(e) => setAuthorsChecked(e)}
                                            isMulti={true}
                                            placeholder="Selecione o(s) autor(es)"
                                            closeMenuOnSelect={false}
                                        />
                                    </InputBox>

                                    <InputBox>
                                        <h3>Tags:</h3>                       
                                        <Select
                                            styles={styles}
                                            isClearable
                                            options={tagsOptions}
                                            components={{ Option: ListOptions}}
                                            value={tagsChecked}
                                            onChange={(e) => setTagsChecked(e)}
                                            isMulti={true}
                                            placeholder="Selecione a(s) tag(s)"
                                            closeMenuOnSelect={false}
                                        />                      
                                    </InputBox>  
                                </DivInputs>
                                
                                <Div>
                                    <AdicionarButton
                                        type="submit"
                                        onClick ={handlePesquisa}> 
                                        <h3>Filtrar</h3> 
                                    </AdicionarButton> 
                                </Div>
                            </Modal>

                            <AreaPesquisa>
                                <input value={title}
                                            onChange={(event) =>
                                                setTitle(event.target.value)}/>
                                <Botao onClick={handlePesquisaTitulo}> <IoSearchSharp /> </Botao>
                            </AreaPesquisa>
                        </Container>
                        <Slides />
                    </Divisao>
                    <Destaques>
                        <h1>Mais acessados</h1>
                        <CarrosselAccessed />
                    </Destaques>
                    <Destaques>
                        <h1>Premiados</h1>
                        <CarrosselAward />
                    </Destaques>
                </Background>
                <Footer />
            </ThemeProvider>
        </>
    )
}