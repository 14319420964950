import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import Modal from 'react-modal';
import ReactLoading from "react-loading";

import { FaArrowLeft } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md'
import { FiExternalLink } from 'react-icons/fi'

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { Date_Picker } from "../../Components/MyDatePicker/styles";
import { ReturnButton, InputBox, Input, UploadSelection, UploadOptions, InputDescription, InputList } from '../AdicionarArquivo/styles'
import { Title } from "../AdicionarColaborador/styles";
import { Buttons, EditBtn, DeleteBtn, ModalContainer } from "../AlterarColaborador2/styles";
import { Container, ColumnLeft, ColumnRight, FileContainer, FilePreview, FileOptions, FileButtons, Bottom, EditMediaBtn, EditButton, DeleteButton } from "./styles";
import api from "../../Services/api"

export function EditarArquivo() {

    const { id } = useParams();
    const [newTitle, setNewTitle] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [newUploadType, setNewUploadType] = useState("");
    const [newFileUpload, setNewFileUpload] = useState("");
    const [newFileLink, setNewFileLink] = useState("")
    const [newCategoryChecked, setNewCategoryChecked] = useState();
    const [newAreaChecked, setNewAreaChecked] = useState();
    const [newDate, setNewDate] = useState("");
    const [newAward, setNewAward] = useState("");
    const [newAuthorsChecked, setNewAuthorsChecked] = useState([]);
    const [newTagsChecked, setNewTagsChecked] = useState([]);
    const [uploadUrl, setUploadUrl] = useState();

    const [downloadUrl, setDownloadUrl] = useState();
    const [authorsOptions, setAuthorsOptions] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);

    const [newClickQuantity, setNewClickQuantity] = useState();

    const [loading, setLoading] = useState();
    const [invalidFields, setInvalidFields] = useState(false);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [areasOptions, setAreasOptions] = useState([]);

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = { //Costumização do Modal
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '${props => props.theme.colors.branco}',
          borderRadius: '2vh',
          padding: '4vh 5vw',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        }
      };
        Modal.setAppElement('#root');

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/admin`)
    }

    // ---------------------- Requisição para pegar as informações do arquivo --------------------------------

    useEffect(() => {
        const getFile =  async () => {
            setLoading(true);
            try {
                const response = await api.get("/file/" + id);
                setNewTitle(response.data.title);
                setNewDescription(response.data.description);
                setNewUploadType(response.data.type);
                setNewFileUpload(response.data.media_path);
                setNewFileLink(response.data.media_path);
                const categoryOption = response.data.categories_associated.map((category) => ({
                    value: category.name,
                    label: category.name,
                    id: category.id,
                }));
                setNewCategoryChecked(categoryOption);
                const areaOption = response.data.areas_associated.map((area) => ({
                    value: area.name,
                    label: area.name,
                    id: area.id,
                }));
                setNewAreaChecked(areaOption)
                setNewDate(new Date(response.data.year, 0)); 
                setNewAward(response.data.awarded);
                const tagOptions = response.data.tags_associated.map((tag) => ({
                    value: tag.name,
                    label: tag.name,
                    id: tag.id,
                }));
                setNewTagsChecked(tagOptions);
                const authorOptions = response.data.authors_associated.map((author) => ({
                    value: author.name,
                    label: author.name,
                    id: author.id,
                }));
                setNewAuthorsChecked(authorOptions);
                response.data.type === "link" ? getLink(response.data.media_path) : getUpload(response.data.media_path);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        getFile();
    }, []);

    const getUpload = (media_path) => {
        setUploadUrl("https://etarserra.fly.dev/media/" + media_path);
        setDownloadUrl("https://etarserra.fly.dev/media/download/" + media_path);
    };

    const getLink = () => {}

    // ---------------------- Patch de arquivo do tipo upload --------------------------------

    const handleEditUpload = async () => {
        setLoading(true);
        try {
            let myFunc = num => Number(num);
            var category = newCategoryChecked[0] ? [newCategoryChecked[0].id] : [newCategoryChecked.id];
            var area =  newAreaChecked[0] ? [newAreaChecked[0].id] : [newAreaChecked.id];
            var year = Number((String(newDate)).slice(11, 15))
            const data = {
                title: newTitle,
                category: category,
                area: area,
                year: year,
                awarded: newAward,
                description: newDescription,
                author: newAuthorsChecked.map((author) => author.id),
                tag: newTagsChecked.map((tag) => tag.id),
            };
            console.log(data)
            await api.patch("/file/" + id, data);
            alert("Arquivo editado com sucesso");
        } catch (error) {
            console.log(error);
            alert("Erro ao editar arquivo!");
        }
        setLoading(false);
    }

    // ---------------------- Patch de arquivo do tipo link --------------------------------

    const handleEditLink = async () => {
        setLoading(true);
        try {
            let myFunc = num => Number(num);
            var category = newCategoryChecked[0] ? [newCategoryChecked[0].id] : [newCategoryChecked.id];
            var area =  newAreaChecked[0] ? [newAreaChecked[0].id] : [newAreaChecked.id];
            var link = (newFileLink.replace("youtu.be/", "youtube.com/embed/").replace("watch?v=", "embed/")).slice(0, 41)
            var year = Number((String(newDate)).slice(11, 15))
            const data = {
                title: newTitle,
                description: newDescription,
                type: newUploadType,
                media_path: link,                
                category: category,
                area: area,
                year: year,
                awarded: newAward,
                author: newAuthorsChecked.map((author) => author.id),
                tag: newTagsChecked.map((tag) => tag.id),
            };
            await api.patch("/file/" + id, data);
            alert("Arquivo editado com sucesso");
        } catch (error) {
            console.log(error);
            alert("Erro ao editar arquivo!");
        }
        setLoading(false);
    }

    // ---------------------- Delete de arquivo --------------------------------

    const handleDeleteFile = async() => {
        setLoading(true);
        try {
            if (newUploadType !== "link"){
                await api.delete("/media/" + newFileUpload);
            }
            fileDelete();
        } catch (error) {
            console.log(error);
            alert("Erro ao deletar upload!");
        }
        setLoading(false);
    };
    
    const fileDelete = async () => {
        setLoading(true);
        try {
            await api.delete("/file/" + id);
            alert("Arquivo deletado!");
            handleNavigate(-1)
        } catch (error) {
            console.log(error);  
            alert("Erro ao deletar arquivo!");
        }
        setLoading(false);
    }

    // ---------------------- Detecta se todos os campos foram preenchidos para habilitar o botão --------------------------------

    useEffect(() => {
        if ( !newTitle || !newDescription || !newCategoryChecked || !newAreaChecked || !newDate || !newAward || !newAuthorsChecked || !newTagsChecked )
            {setInvalidFields(true);
        }
        else if ( !newFileUpload && !newFileLink )
            {setInvalidFields(true);
        }
        else{
            setInvalidFields(false);
        }
    }, [
        newTitle, newDescription, newCategoryChecked, newAreaChecked, newFileUpload, newFileLink, newDate, newAward, newAuthorsChecked, newTagsChecked  
    ]);

    //---------------------- Faz requisições de busca e criação de Categorias --------------------------------

    const searchCategoria = async () => {
        setLoading(true);
        try {
            const response = await api.get("/category");          // Colocar rota do back
            const categoryOptions = response.data.map((category) => ({
                id: category.id,
                value: category.name,
                label: category.name,
            }));
            setCategoriesOptions(categoryOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchCategoria();
        console.log(newCategoryChecked);
        console.log(typeof(newCategoryChecked))
    }, [newTitle]);

    const createCategory = async (categoryChecked) => {
        setLoading(true);
        try {
            const data = {
                name: categoryChecked,
            };
            await api.post("/category", data);
            searchCategoria();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar categoria!");
        }
        setLoading(false);
    };
       
    const { Option } = components;
    const CategoriesOption = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

        useEffect(() => {
            setNewCategoryChecked(newCategoryChecked);
        }, [setNewCategoryChecked, newCategoryChecked])

    const styles = {
        container: base => ({
            ...base,
            width: "20vw"
        })
    };

    //---------------------- Faz requisições de busca e criação de Áreas --------------------------------

    const searchArea = async () => {
        setLoading(true);
        try {
            const response = await api.get("/area");          // Colocar rota do back
            const areaOptions = response.data.map((area) => ({
                id: area.id,
                value: area.name,
                label: area.name,
            }));
            setAreasOptions(areaOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchArea();
    }, [newAreaChecked]);

    const createArea = async (areaChecked) => {
        setLoading(true);
        try {
            const data = {
                name: areaChecked,
            };
            await api.post("/area", data);
            searchArea();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar área!");
        }
        setLoading(false);
    };
       
    const AreasOption = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

        useEffect(() => {
            setNewAreaChecked(newAreaChecked);
        }, [setNewAreaChecked, newAreaChecked])

    // ---------------------- Faz requisições de busca e criação de Autores --------------------------------

    const searchAuthor = async () => {
        setLoading(true);
        try {
            const response = await api.get("/autores");
            const authorOptions = response.data.map((author) => ({
                id: author.id,
                value: author.name,
                label: author.name,
            }));
            setAuthorsOptions(authorOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchAuthor();
    }, [newAuthorsChecked]);

    const createAuthor = async (authorsChecked) => {
        setLoading(true);
        try {
            const data = {
                name: authorsChecked,
            };
            await api.post("/autor/create", data);
            searchAuthor();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar autor!");
        }
        setLoading(false);
    };
       
    const AuthorsOption = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

        useEffect(() => {
            setNewAuthorsChecked(newAuthorsChecked);
        }, [setNewAuthorsChecked, newAuthorsChecked])


    // ---------------------- Faz requisições de busca e criação de Tags --------------------------------

    const searchTags = async () => {
        setLoading(true);
        try {
            const response = await api.get("/tags");
            const tagOptions = response.data.map((tag) => ({
                id: tag.id,
                value: tag.name,
                label: tag.name,
            }));
            setTagsOptions(tagOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchTags();
    }, [newTagsChecked]);

    const createTag = async (tagsChecked) => {
        setLoading(true);
        try {
            const data = {
                name: tagsChecked,
            };
            await api.post("/tag/create", data);
            searchTags();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar tag!");
        }
        setLoading(false);
    };

    const TagsOption = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

        useEffect(() => {
            setNewTagsChecked(newTagsChecked);
        }, [setNewTagsChecked, newTagsChecked])

    // ---------------------- Switch para rederização de diferentes tipos de arquivos no preview --------------------------------

    const renderFiles = () => {
        switch(newUploadType) {
            case "png":
                return(<img src={uploadUrl} alt="file"/>)
            case "jpeg":
                return(<img src={uploadUrl} alt="file"/>)
            case "jpg":
                return(<img src={uploadUrl} alt="file"/>)
            case "gif":
                return(<img src={uploadUrl} alt="file"/>)
            case "mp4":
                return(<iframe src={uploadUrl}></iframe>)
            case "webm":
                return(<iframe src={uploadUrl}></iframe>)
            case "pdf": 
                return( <object data={uploadUrl} type="application/pdf">
                            <p>Seu navegador não tem um plugin pra PDF</p>
                        </object>
                )
            default:
                return(<iframe src={newFileLink}></iframe>)
        }
    }

    return (
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>             
                <ColumnLeft>
                    <InputBox>
                        <h3>Título:</h3>
                        <Input 
                            placeholder=""
                            value={newTitle}
                            onChange={event => setNewTitle(event.target.value)}>
                        </Input>
                    </InputBox>

                    <InputBox>
                        <h3>Descrição:</h3>
                        <InputDescription
                            placeholder=""
                            value={newDescription}
                            onChange={event => setNewDescription(event.target.value)}>
                        </InputDescription>
                    </InputBox>

                    <InputBox>
                        <UploadSelection>
                            <h3>Arquivo:</h3>


                            </UploadSelection>
                            <EditMediaBtn to={`/editar-media/` + id}> <h4>Editar arquivo</h4> </EditMediaBtn>
                    </InputBox>      

                    <InputBox>
                        <h3>Categoria:</h3>
                        <CreatableSelect
                            styles={styles}
                            isCleartable
                            options={categoriesOptions}
                            components={{ Option: CategoriesOption }}
                            value={newCategoryChecked}
                            onChange={(e) => setNewCategoryChecked(e)}
                            placeholder="Selecione a categoria"
                            onCreateOption = {createCategory}
                        />           
                    </InputBox>

                    <InputBox>
                        <h3>Área:</h3>
                        <CreatableSelect
                            styles={styles}
                            isCleartable
                            options={areasOptions}
                            components={{ Option: AreasOption }}
                            value={newAreaChecked}
                            onChange={(e) => setNewAreaChecked(e)}
                            placeholder="Selecione a área"
                            onCreateOption = {createArea}
                        />       
                    </InputBox>

                    <InputBox>
                        <h3>Ano:</h3>
                        <Date_Picker
                            value={newDate}
                            onChange={(event) => setNewDate(event)}   
                            maxDetail="decade"
                            maxDate={new Date()}
                        />   
                    </InputBox>  
                
                    <InputBox>
                        <h3>Premiação:</h3>                   
                        <InputList
                            value={newAward}
                            onChange={(event) =>
                                setNewAward(event.target.value)}
                            >
                            <option disabled={newAward}></option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                            <option value="Não informado">Não informado</option>
                        </InputList>                         
                    </InputBox> 
                </ColumnLeft>

                <ColumnRight>
                <FileContainer>
                        <FilePreview>
                            {renderFiles()}
                        </FilePreview>
                            
                        <FileOptions>
                            {/* Como baixar? */}
                            {newUploadType === "link" ? (
                                <></>
                            ) : (
                                <a href={downloadUrl} target="_blank" rel="external">
                                <FileButtons href={downloadUrl} target="_blank" rel="external">
                                    <h4>Baixar arquivo</h4>
                                    <MdFileDownload/>
                                </FileButtons>
                                </a>
                            )}
                            
                            <a href={uploadUrl} target="_blank" rel="external">
                                <FileButtons href={uploadUrl} target="_blank" rel="external">
                                    <h4>Abrir em uma nova aba</h4>
                                    <FiExternalLink/>
                                </FileButtons>
                            </a>
                        </FileOptions>
                    </FileContainer>

                    <InputBox>
                        <h3>Autor:</h3>
                        <CreatableSelect
                            styles={styles}
                            isCleartable
                            options={authorsOptions}
                            components={{ Option: AuthorsOption }}
                            value={newAuthorsChecked}
                            onChange={(e) => setNewAuthorsChecked(e)}
                            isMulti={true}
                            placeholder="Selecione o(s) autor(es)"
                            closeMenuOnSelect={false}
                            onCreateOption = {createAuthor}
                        />
                    </InputBox>

                    <InputBox>
                        <h3>Tags:</h3>  
                        <CreatableSelect
                            styles={styles}
                            isCleartable
                            options={tagsOptions}
                            components={{ Option: TagsOption }}
                            value={newTagsChecked}
                            onChange={(e) => setNewTagsChecked(e)}
                            isMulti={true}
                            placeholder="Selecione a(s) tag(s)"
                            closeMenuOnSelect={false}
                            onCreateOption = {createTag}
                        />                        
                    </InputBox>

                    <Bottom>
                        {newUploadType === "link" ? (
                            <EditButton
                                type="submit"
                                disabled={invalidFields}
                                onClick={(event) => handleEditLink(event)}>
                                {loading ? (<ReactLoading
                                                type="spin"
                                                color="#ffffff"
                                                height={"20px"}
                                                width={"20px"}/>
                                ) : (<h3>Salvar alterações</h3>)}                                   
                            </EditButton> 
                        ) : (
                            <EditButton
                                type="submit"
                                disabled={invalidFields}
                                onClick={(event) => handleEditUpload(event)}>
                                {loading ? (<ReactLoading
                                                type="spin"
                                                color="#ffffff"
                                                height={"20px"}
                                                width={"20px"}/>
                                ) : (<h3>Salvar alterações</h3>)}                                  
                            </EditButton>  
                        )}
                        <DeleteButton
                            type="submit"
                            onClick={openModal}
                        >                             
                            <h3>Excluir arquivo</h3> 
                        </DeleteButton> 
                    </Bottom>
                    <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <ModalContainer>
                                <Title>Tem certeza que deseja excluir esse arquivo?</Title>
                                <Buttons>
                                    <EditBtn onClick={closeModal}> <h4>CANCELAR</h4> </EditBtn>
                                    <DeleteBtn onClick={handleDeleteFile}> <h4>EXCLUIR</h4> </DeleteBtn>
                                </Buttons>
                            </ModalContainer>
                        </Modal>  
                </ColumnRight>
            </Container>
            <Footer/>
        </>
    )
}