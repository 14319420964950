import React from "react";
import { createContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import api from "../Services/api";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();

    const [token, setToken] = useState(() => {
        const token = localStorage.getItem("access_token");
        if (token) {
            return token;
        }
        return "";
    });

    const [user, setUser] = useState(() => {
        const user = localStorage.getItem("user");
        if (user) {
            return JSON.parse(user);
        }
        return {}; 
    });

    const [admin, setAdmin] = useState(() => {
        const admin = localStorage.getItem("isAdmin");
        if (admin) {
            return admin;
        }
        return ; 
    });

    const [id, setId] = useState(() => {
        const id = localStorage.getItem("id");
        if (id) {
            return id;
        }
        return ; 
    });

    
    const login = useCallback(
        async (data, setLoading, setLoginError, navigate) => {
             //diz se o usuário é admin ou não (colaborador)
            setLoading(true);
            try {
                const response = await api.post("/login", data);
                console.log(response.data);
                const token = response.data.access_token;
                setToken(token);
                const user = response.data.user;
                setUser(user);
                const id = response.data.user.id;
                setId(id);
                localStorage.setItem("access_token", token);
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("id", id);
                const admin = response.data.isAdmin;
                localStorage.setItem("isAdmin",admin);
                setAdmin(admin);
                console.log(admin)
                window.location.reload(false);
            } catch (error) {
                console.log(error);
                alert(`Erro ao realizar o login.`);
                setLoginError(true);
            }
            setLoading(false);
            return admin;
        },
        []
    );

    const logout = useCallback(() => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("id");
        localStorage.removeItem("msg");
        setToken("");
        setUser({}); 
        navigate("/");
    }, []);

    const isAuthenticated = () =>
    localStorage.getItem("access_token") ? true : false;

  const isAuthorized = () => {
    console.log("is Admin?");
    console.log(admin);
    const userPrivilage = admin;

    return userPrivilage;
  };

    return(
            <AuthContext.Provider value = {{login, logout, token, user, admin, id, setUser, isAuthenticated, isAuthorized}}>
                {children}
            </AuthContext.Provider>
    )
}

export default AuthContext;