import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { FaArrowLeft } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md';
import { FiExternalLink } from 'react-icons/fi';
import { AiFillFile, AiFillPicture, AiOutlineLink } from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { ReturnButton } from '../AdicionarArquivo/styles';
import { FilePreview, FileOptions, FileButtons } from "../EditarArquivo/styles";
import { Container, ColumnLeft, ColumnCenter, ColumnRight, InfosList, List, Files, Info, TypeFile, FileContainer, DescContainer, FileTitle } from "./styles";
import api from "../../Services/api";

export function Arquivo() {

    const [filesList, setFilesList] = useState([]);
    const [uploadUrl, setUploadUrl] = useState();
    const [downloadUrl, setDownloadUrl] = useState();

    const location = useLocation()
    const file = location.state

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(-1)
    }

    {file.type === "link" ? (
        useEffect(() => {
            const getLink = async () => {
                
            };
            getLink();
        }, [file])
    ) : (
        useEffect(() => {
            setUploadUrl("https://etarserra.fly.dev/media/" + file.media_path);
            setDownloadUrl("https://etarserra.fly.dev/media/download/" + file.media_path);
        }, [file])
    )}

    useEffect(() => {
        const getFiles = async () => {
            try {
                const tagsId = file.tags.map((tag) => tag.id)
                const response = await api.get("/file?tags=[" + tagsId + "]");
                const files = response.data.map((file) => ({
                    id: file.id,
                    title: file.title,
                    category: file.categories_associated,
                    area: file.areas_associated,
                    year: file.year,
                    awarded: file.awarded,
                    description: file.description,
                    tags: file.tags_associated,
                    authors: file.authors_associated,
                    click_quantity: file.click_quantity,
                    creator_id: file.creator_id,
                    media_path: file.media_path,
                    type: file.type,
                })); 
                setFilesList(files);
                console.log(files)
            } catch (error) {
                console.log(error);
            }
        };
        getFiles();
    }, [file])

    const renderFiles = () => {
        switch(file.type) {
            case "png":
                return(<img src={uploadUrl} alt="file"/>)
            case "jpeg":
                return(<img src={uploadUrl} alt="file"/>)
            case "jpg":
                return(<img src={uploadUrl} alt="file"/>)
            case "gif":
                return(<img src={uploadUrl} alt="file"/>)
            case "mp4":
                return(<iframe src={uploadUrl}></iframe>)
            case "webm":
                return(<iframe src={uploadUrl}></iframe>)
            case "pdf": 
                return( <object data={uploadUrl} type="application/pdf">
                            <p>Seu navegador não tem um plugin pra PDF</p>
                        </object>
                )
            default:
                return(<iframe src={file.media_path}></iframe>)
        }
    }

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>
                <ColumnLeft>
                    <FileTitle>{file.title}</FileTitle>
                    <FileContainer>
                        <FilePreview>
                            {renderFiles()}
                        </FilePreview>
                            
                        <FileOptions>
                            {file.type === "link" ? (
                                <>
                                    <a href={file.media_path} rel="external" target="_blank">
                                        <FileButtons href={uploadUrl} target="_blank" rel="external">
                                            {file.mediaPath}
                                            <h4>Abrir em uma nova aba</h4>
                                            <FiExternalLink/>
                                        </FileButtons>
                                    </a>
                                </>
                            ) : (
                                <>
                                    <a href={downloadUrl} rel="external">
                                        <FileButtons href={downloadUrl} target="_blank" rel="external">
                                            <h4>Baixar arquivo</h4>
                                            <MdFileDownload/>
                                        </FileButtons>
                                    </a>
                                    <a href={uploadUrl} rel="external" target="_blank">
                                        <FileButtons href={uploadUrl} target="_blank" rel="external">
                                            <h4>Abrir em uma nova aba</h4>
                                            <FiExternalLink/>
                                        </FileButtons>
                                    </a>
                                </>
                            )}
                        </FileOptions>
                        <DescContainer>
                            <h3>Descrição:</h3> <p>{file.description}</p>
                        </DescContainer>
                    </FileContainer>
                </ColumnLeft>
                <ColumnCenter>
                    <h3>Categoria: <p>{file.category[0].name}</p></h3>
                    <h3>Área: <p>{file.area[0].name}</p></h3>
                    <h3>Ano: <p>{file.year}</p></h3>
                    <h3>Premiado: <p>{file.awarded}</p></h3>
                    <h3>Autores:</h3>
                    {
                        file.authors.map((author, index) => {
                            return(
                                <InfosList key = {index}>{author.name}</InfosList>
                            )
                        })
                    }
                    <h3>Tags:</h3>
                    {
                        file.tags.map((tag, index) => {
                            return(
                                <InfosList  key = {index}>{tag.name}</InfosList>
                            )
                        })
                    }
                </ColumnCenter>
                <ColumnRight>
                    <h1>Recomendados</h1>
                    <List>
                        {
                            filesList.map((fileRender, index) => {
                                if (fileRender.id === file.id) {
                                    return("")
                                } else
                                return(
                                    <Files key = {index} to={`/arquivo/${fileRender.id}`} state={fileRender}>
                                        <Info>
                                            <h2>{fileRender.title}</h2>
                                            <p>{fileRender.category[0].name}</p>
                                        </Info>
                                        <TypeFile>  
                                            {fileRender.type === "png" ? (
                                                <AiFillPicture/>
                                            ) : (fileRender.type === "jpeg" ? (
                                                <AiFillPicture/>
                                            ) : (fileRender.type === "jpg" ? (
                                                <AiFillPicture/>
                                            ) : (fileRender.type === "gif" ? (
                                                <AiFillPicture/>
                                            ) : (fileRender.type === "mp3" ? (
                                                <BsFillCameraVideoFill/>
                                            ) : (fileRender.type === "mp4" ? (
                                                <BsFillCameraVideoFill/>
                                            ) : (fileRender.type === "link" ? (
                                                <AiOutlineLink/>
                                            ) : (
                                                <AiFillFile/>
                                            )))))))}
                                        </TypeFile>                             
                                    </Files>
                                )
                            })
                        }
                    </List>
                </ColumnRight>                
            </Container>
            <Footer/>
        </>
    )
}