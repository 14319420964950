import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { FaArrowLeft } from 'react-icons/fa';
import ReactLoading from "react-loading"

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";

import { ReturnButton } from "../AdicionarArquivo/styles"
import { Container } from "../EditarArquivo/styles"
import { Box } from "../Administracao/styles";
import { Title, Inputs, InputSelect, Input, AdicionarButton } from "./styles";
import api from "../../Services/api";

export function AdicionarColaborador () {

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/gerenciamento`)
    }

    const [isAdmin, setIsAdmin] = useState();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [invalidFields, setInvalidFields] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const data = {
                isAdmin: isAdmin === "true" ? true : false ,
                name: name,
                email: email,
                password: password
            };
            // Tirar esse console
            console.log(typeof(isAdmin))
            console.log(data);
            const response = await api.post("/colaborador/cadastro", data);
            console.log(response.data);
            handleNavigate(-1)
            alert("Colaborador(a) criado!");
        } catch (error) {
            console.log(error);
            alert("Erro ao criar colaborador(a)!");
        }
        setLoading(false);
    }
    
    useEffect(() => {
        if ( !isAdmin || !name || !email || !password )
            {setInvalidFields(true);
        }
        else{
            setInvalidFields(false);
        }
    }, [
        isAdmin, name, email, password  
    ]);

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>
                <Box>
                    <Title> <h2>Adicione um(a)</h2> 
                        <h2> colaborador(a)</h2> 
                    </Title>
                    <Inputs>
                        <InputSelect
                            value={isAdmin}
                            onChange={(event) =>
                                setIsAdmin(event.target.value)}
                        >
                            <option disabled={isAdmin}>Tipo de usuário</option>
                            <option value={false}>Colaborador</option>
                            <option value={true}>Administrador</option>
                        </InputSelect>
                        
                        <Input 
                            type="text"
                            placeholder="Nome"
                            value={name}
                            onChange={event => setName(event.target.value)}>
                        </Input>

                        <Input 
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}>
                        </Input>

                        <Input 
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={event => setPassword(event.target.value)}>
                        </Input>

                        <AdicionarButton onClick={handleSubmit} disabled={invalidFields}>
                            {loading ? (
                                <ReactLoading
                                    type="spin"
                                    color="#ffff"
                                    height={"25px"}
                                    width={"25px"}/>
                            ) : (
                                <h4>ADICIONAR</h4>
                            )}                            
                        </AdicionarButton>
                    </Inputs>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}