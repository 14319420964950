import React from "react";
import { Foot } from './styles';
import footer from '../../Assets/footer.svg';

export function Footer(){

    return (
        <Foot> 
            <h4> Financiado por:</h4>
            <img src={footer} alt="footer" height={50}/>
        </Foot>
    )
}