import React, {useState} from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../Styles/Global'
import {theme} from '../../Styles/Theme'
import { Header } from "../../Components/Header";
import {Footer} from "../../Components/Footer";
import { Botao } from '../Login/styles'
import { Background, Container } from './styles';
import { useNavigate } from "react-router-dom";
import api from '../../Services/api';

export default function VerificarPin() {

    const [pin, setPin] = useState("");

    const pinEmail = localStorage.getItem("email");
    
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        try {
            const data = {
                email: pinEmail,
                verificationPin: pin,
            }
            const response = await api.post("/pinInserido", data)
            response.data.isPinValid ?
            navigate(`/redefinir-senha/${response.data.id}`):alert(`PIN INCORRETO!`); setPin("")
            //Vai levar para a tela de redefinir a senha
        } catch (error) {
            console.log(error);
            alert(`PIN INCORRETO!`);
        }
    };

    return(
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Header />
                <Background>
                    <Container>
                        <h1>Verificar PIN</h1>
                        <p>Digite aqui o Pin que foi enviado para o seu E-mail, para verificarmos se é você:</p>
                        <input type = 'number'
                        placeholder='PIN'
                        value={pin}
                        onChange={(event) => setPin(event.target.value)}/>
                        <Botao  onClick={(event) => handleSubmit(event)}>PRÓXIMO</Botao>
                        <a href = "/esqueci-senha">Voltar</a>
                    </Container>
                </Background>

                <Footer />
            </ThemeProvider>
        </>
    )
}