import styled from 'styled-components'
import { Link } from "react-router-dom"

export const Box = styled.div`
    background-color: ${props => props.theme.colors.branco};
    height: 55vh;
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 5vh;
    padding-top: 2px;
    padding-bottom: 5vh;
    border-radius: 8px;
` 

export const Title = styled.div`
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h1{
        color: ${props => props.theme.colors.azul};
    }
`

export const Buttons = styled.div`
    width: 25vw;
    height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

`

export const GerenciamentoBtn = styled(Link)`
    background-color: ${props => props.theme.colors.azul};
    width: 100vw;
    max-width: 200px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    h5{
        color: ${props => props.theme.colors.branco};
    }
`

export const AdicionarBtn = styled(Link)`
    background-color: ${props => props.theme.colors.verde};
    width: 100vw;
    max-width: 200px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    h5{
        color: ${props => props.theme.colors.branco};
    }
`

export const Search = styled.div`
     width: 100vw;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: space-around;
`

export const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    border: 3px solid ${props => props.theme.colors.azul};
    padding: 2px;
`

export const Input = styled.input`
    background-color: ${props => props.theme.colors.branco};
    height: 4vh;
    width: 28vw;
    padding-left: 10px;
    cursor: text;
    textarea:focus, input:focus{
        box-shadow: 0 0 0 0;
        outline: 0;
    }
    ::placeholder{
        color: ${props => props.theme.colors.azul};
    }
`

export const SearchIcon = styled.button`
    background-color: ${props => props.theme.colors.azul};
    color: ${props => props.theme.colors.branco};
    border-radius: 8px;
    width: 70px;
    height: 35px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :disabled{
        background-color: ${props => props.theme.colors.branco};
        color: ${props => props.theme.colors.azul};
    }
`

export const List = styled.ul`
    background-color: ${props => props.theme.colors.branco};
    width: 250px;
    min-height: 15vh;
    max-height: 15vh;
    margin-top: 2vh;
    overflow-y: scroll; 
    ::-webkit-scrollbar {
    width: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.colors.azul}}
`

export const Files = styled(Link)`
    font-size: small;
    color: ${props => props.theme.colors.azul};
    border: 2px solid ${props => props.theme.colors.azul};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 2vh;
    padding-right: 1vw;
    h2{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
`

export const EditarBtn = styled.div`
    background-color: ${props => props.theme.colors.branco};
    color: ${props => props.theme.colors.azul};
    
`