import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { FaArrowLeft } from 'react-icons/fa';
import ReactLoading from 'react-loading';

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { ReturnButton } from "../AdicionarArquivo/styles"
import { Container } from "../EditarArquivo/styles"
import { Box } from "../Administracao/styles";
import { Title, Inputs, Input } from "../AdicionarColaborador/styles";
import { Infos, Bottom, EditBtn } from "./styles"
import api from "../../Services/api";

export function AlterarColaborador3 () {

    const { id } = useParams();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [loading, setLoading] = useState();
    const [invalidFields, setInvalidFields] = useState(false);

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/editar-excluir-colaborador/` + id)
    }

    useEffect(() => {
        const getUser =  async () => {
            setLoading(true);
            try {
                const response = await api.get("/colaborador/detalhes/" + id);
                setName(response.data.name);
                setEmail(response.data.email);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        };
        getUser();
    }, []);

    const handleEdit = async () => {
        setLoading(true);
        try {
            const data = {
                password: newPassword
            };
            await api.patch("/colaborador/detalhes/" + id, data);
            handleNavigate()
        } catch (error) {
            console.log(error);
            alert("Erro ao editar colaborador(a)!");
        }
        setLoading(false);
    }

    useEffect(() => {
        if ( !newPassword 
        )
            {setInvalidFields(true);
        }
        else{
            setInvalidFields(false);
        }
    }, [newPassword]);

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>
                <Box>
                    <Title> <h2>Redefinição de senha</h2></Title>
                    <Infos>
                        <h4>Colaborador: <p>{name}</p></h4>
                        <h4>Email: <p>{email}</p></h4>
                    </Infos>
                    <Inputs>
                        <Input 
                            type="password"
                            placeholder="Nova senha"
                            value={newPassword}
                            onChange={event => setNewPassword(event.target.value)}>
                        </Input>
                        <Bottom>
                            <EditBtn onClick={handleEdit} disabled={invalidFields}>
                                {loading ? (<ReactLoading
                                        type="spin"
                                        color="#ffffff"
                                        height={"20px"}
                                        width={"20px"}/>
                                ) : (<h4>Redefinir senha</h4>)}                                   
                            </EditBtn>
                        </Bottom>                   
                    </Inputs>
                </Box>
            </Container>
        <Footer/>
    </>
       
    )
}