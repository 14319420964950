import React from 'react'
import { useNavigate } from 'react-router-dom'

import { FaArrowLeft } from 'react-icons/fa';
import sad from "../../Assets/sad.png"

//Styles
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../Styles/Global'
import {theme} from '../../Styles/Theme'
import { Background, Separacao } from './styles';

//Componentes
import { Header } from "../../Components/Header";
import {Footer} from "../../Components/Footer";
import { ReturnButton } from '../ResultadosPesquisa/styles'


export default function NotFound() {

    const navigate = useNavigate()

    function handleNavigate(){
        navigate("/")
    }


    return(
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Header />
                <ReturnButton onClick={handleNavigate} > <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
                <Background>
                    <img src={sad} alt="sad face"/>
                    <Separacao>
                        <h1>404</h1>
                        <p>PAGE NOT FOUND!</p>
                    </Separacao>
                </Background>

                <Footer />
            </ThemeProvider>
        </>
    )
}