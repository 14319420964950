import styled from "styled-components";

export const Container = styled.div`
    width: 640px;
    height: 360px;
    position: relative;
    border-radius: 32px;
`
export const ImageContainer = styled.img`
    width: 640px;
    height: 360px;
    object-fit: cover;
`