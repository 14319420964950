export const theme = {
    colors: {
        'branco': '#F7F7F7',
        'azul': '#0490C9',
        'azul_claro': '#0199D0',
        'azul_2' : '#65B2D2',
        'verde': '#2FA930',
        'verde_claro': '#9ACA9D',
        'verde_escuro': 'green',
        'amarelo': '#FAD912',
        'amarelo_escuro': '#DAA520',
        'vermelho': 'red',
        'vermelho_escuro': '#8D0017'
    }
}