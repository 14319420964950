import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from "react-router-dom"

//Icones
import { FaArrowLeft } from 'react-icons/fa';
import { IoSearchSharp } from "react-icons/io5";
import { AiFillFile, AiFillPicture, AiOutlineLink } from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";

//Componentes
import { Header } from '../../Components/Header'
import { Footer } from '../../Components/Footer';


import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../../Styles/Global';
import { theme } from '../../Styles/Theme';
import ReactLoading from "react-loading";
import Select, { components } from "react-select";
import { Date_Picker } from '../../Components/MyDatePicker/styles';
import { AdicionarButton, AreaPesquisa, Botao, ColumnLeft, ColumnRight, Container, FundoFiltro, InputBox, InputList, ReturnButton, Titulo, Div, DivInputs, ListaResults, Esquerda, Direita, TituloResultado, CadaResult, Descricao, TypeFile } from './styles'

import api from "../../Services/api"

function ResultadosPesquisa() {
    const navigate = useNavigate()

    function handleNavigate(){
        navigate("/")
    }

     //Os Inputs
     const [filterCategoryChecked, setFilterCategoryChecked] = useState();
     const [filterAreaChecked, setFilterAreaChecked] = useState();
     const [filterYear, setFilterYear] = useState("");
     const [filterAward, setFilterAward] = useState("");
     const [filterAuthorsChecked, setFilterAuthorsChecked] = useState([]);
     const [filterTagsChecked, setFilterTagsChecked] = useState([]);
     const [title, setTitle] = useState([]);
 
     const [loading, setLoading] = useState(false);
     const [categoriesOptions, setCategoriesOptions] = useState([]);
     const [areasOptions, setAreasOptions] = useState([]);
     const [authorsOptions, setAuthorsOptions] = useState([]);
     const [tagsOptions, setTagsOptions] = useState([]);

     const {category, area, min_year, max_year, award, authors, tags} = useParams();
     
     useEffect(() =>{
        min_year.slice(9) === "" ? setFilterYear("") : setFilterYear(new Date((min_year.slice(9)), 0))
        setFilterAward(award.slice(8))
        setFilterAuthorsChecked([])
        setFilterTagsChecked([])
        searchAuthor();
        searchTags();
        searchArea();
        searchCategory();
     },[category, area, min_year, max_year, award, authors, tags])

     const [results, setResults] = useState([]);

    // ---------------------- Faz requisições de busca de Categorias --------------------------------

    const searchCategory = async () => {
        setLoading(true);
        try {
            const response = await api.get("/category");      //Colocar rota do back
            const categoriesOptions = response.data.map((category) => ({
                id: category.id,
                value: category.name,
                label: category.name,
            }));
            setCategoriesOptions(categoriesOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const { Option } = components;
    const ListOptions = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
    );

    const styles = {
        container: base => ({
            ...base,
            width: "20vw"
        })
    };

    // ---------------------- Faz requisições de busca de Áreas --------------------------------

    const searchArea = async () => {
        setLoading(true);
        try {
            const response = await api.get("/area");      //Colocar rota do back
            const areasOptions = response.data.map((area) => ({
                id: area.id,
                value: area.name,
                label: area.name,
            }));
            setAreasOptions(areasOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    // ---------------------- Faz requisições de busca de Autores --------------------------------

     const searchAuthor = async () => {
        setLoading(true);
        try {
            const response = await api.get("/autores");
            const authorOptions = response.data.map((author) => ({
                id: author.id,
                value: author.name,
                label: author.name,
            }));
            setAuthorsOptions(authorOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    // ---------------------- Faz requisições de busca de Tags --------------------------------

    const searchTags = async () => {
        setLoading(true);
        try {
            const response = await api.get("/tags");
            const tagOptions = response.data.map((tag) => ({
                id: tag.id,
                value: tag.name,
                label: tag.name,
            }));
            setTagsOptions(tagOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    // ---------------------- Faz requisições de pesquisa --------------------------------

    useEffect(() => {
        const searchFiles = async () => {
            setLoading(true);
            try{
                var categoryList = category.length === 9 ? "" : `&${category}`
                var areaList = area.length === 5 ? "" : `&${area}`
                var tagsList = tags.length === 5 ? "" : `&${tags}`;             //Sérgio tem que mexer
                var authorsList = authors.length === 8 ? "" : `&${authors}`;    //Sérgio tem que mexer
                const stringRoute = `/file?${award}&${min_year}&${max_year}` + categoryList + areaList + tagsList + authorsList
                const response = await api.get(stringRoute)
                const files = response.data.map((file) => ({
                    id: file.id,
                    title: file.title,
                    category: file.categories_associated, //ver como o back vai retornar 
                    area: file.areas_associated,         //ver como o back vai retornar 
                    year: file.year,
                    awarded: file.awarded,
                    description: file.description,
                    tags: file.tags_associated,
                    authors: file.authors_associated,
                    click_quantity: file.click_quantity,
                    creator_id: file.creator_id,
                    media_path: file.media_path,
                    type: file.type,
                }));
                setResults(files);
            }
            catch (error) {
                console.log(error);
            }
            setLoading(false)
        } 
        searchFiles()
     },[category, area, min_year, max_year, award, authors, tags]);
 
     const handlePesquisa = async () => {
        setLoading(true);
        try {
            var date = (String(filterYear)).slice(11, 15)
            var categoryList = filterCategoryChecked ? `/category=[${filterCategoryChecked.id}]` : `/category=`;
            var areaList = filterAreaChecked ? `/area=[${filterAreaChecked.id}]` : `/area=`;
            var autores = filterAuthorsChecked.map((author) => author.id)
            var authorsList = autores.length > 0 ? `/authors=[${autores}]` : `/authors=`;
            var tag = filterTagsChecked.map((tag) => tag.id)
            var tagsList = tag.length > 0 ? `/tags=[${tag}]` : `/tags=`;   
            const stringRoute = `awarded=${filterAward}/min_year=${date}/max_year=${date}` + categoryList + areaList + tagsList + authorsList;
            navigate(`/resultados-filtro/${stringRoute}`)
        } catch (error) {
            console.log(error);
            alert("Erro ao pesquisar!");
        }
        setLoading(false);
    }

    const handlePesquisaTitulo = async () => {
        setLoading(true);
        try {
            const stringRoute = `title=${title}`;
            navigate(`/resultados-titulo/${stringRoute}`)
        } catch (error) {
            console.log(error);
            alert("Erro ao pesquisar!");
        }
        setLoading(false);
    }

    useEffect(() => {
        setFilterCategoryChecked(categoriesOptions.find(x => x.id === Number(category.slice(10,-1))))
        setFilterAreaChecked(areasOptions.find(x => x.id === Number(area.slice(6,-1))))
    }, [categoriesOptions, areasOptions]);

    const renderResults = results.map((resultado,index)=>(
        <CadaResult key = {index} to={`/arquivo/${resultado.id}`} state={resultado}>
            <Esquerda>
                <TituloResultado>{resultado.title}</TituloResultado>
                <Descricao>{resultado.description}</Descricao>
            </Esquerda>
            <Direita>
                <TypeFile>
                    {resultado.type === "png" ? (
                        <AiFillPicture/>
                    ) : (resultado.type === "jpg" ? (
                        <AiFillPicture/>
                    ) : (resultado.type === "jpeg" ? (
                        <AiFillPicture/>
                    ) : (resultado.type === "mp3" ? (
                        <BsFillCameraVideoFill/>
                    ) : (resultado.type === "mp4" ? (
                        <BsFillCameraVideoFill/>
                    ) : (resultado.type === "link" ? (
                        (<AiOutlineLink/>)
                    ) : (
                        (<AiFillFile/>)
                    ))))))}
                </TypeFile> 
                <div>{resultado.area[0].name}</div>
            </Direita>
        </CadaResult>
    ))

  return (
    <div>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>             
                <ColumnLeft>
                    <AreaPesquisa>
                    <input value={title}
                        onChange={(event) =>
                            setTitle(event.target.value)}/>
                    <Botao onClick={handlePesquisaTitulo}> <IoSearchSharp /> </Botao>
                    </AreaPesquisa>

                    <FundoFiltro>
                        <Titulo>Selecione as opções </Titulo>
                                    
                        <DivInputs>
                            <InputBox>
                                <h3>Categoria:</h3>
                                <Select
                                    styles={styles}
                                    isClearable
                                    options={categoriesOptions}
                                    components={{ Option: ListOptions }}
                                    value={filterCategoryChecked}
                                    onChange={(e) => setFilterCategoryChecked(e)}
                                    placeholder="Selecione a categoria"
                                />      
                            </InputBox>  

                            <InputBox>
                                <h3>Área:</h3>
                                <Select
                                    styles={styles}
                                    isClearable
                                    options={areasOptions}
                                    components={{ Option: ListOptions }}
                                    value={filterAreaChecked}
                                    onChange={(e) => setFilterAreaChecked(e)}
                                    placeholder="Selecione a área"
                                />                      
                            </InputBox>

                            <InputBox>
                                <h3>Ano:</h3>
                                <Date_Picker
                                    value={filterYear}
                                    onChange={(event) => setFilterYear(event)}   
                                    maxDetail="decade"
                                    maxDate={new Date()}
                                />                                        
                            </InputBox>  
                        
                            <InputBox>
                                <h3>Premiação:</h3>
                                <InputList
                                    value={filterAward}
                                    onChange={(event) =>
                                        setFilterAward(event.target.value)}
                                    >
                                    <option value = "">Nenhum</option>
                                    <option value="Sim">Sim</option>
                                    <option value="Não">Não</option>
                                    <option value="Não informado">Não informado</option>
                                </InputList>               
                            </InputBox> 

                            <InputBox>
                                <h3>Autor:</h3>
                                <Select
                                    styles={styles}
                                    isClearable
                                    options={authorsOptions}
                                    components={{ Option: ListOptions}}
                                    value={filterAuthorsChecked}
                                    onChange={(e) => setFilterAuthorsChecked(e)}
                                    isMulti={true}
                                    placeholder="Selecione o(s) autor(es)"
                                    closeMenuOnSelect={false}
                                />
                            </InputBox>

                            <InputBox>
                                <h3>Tags:</h3>
                                <Select
                                    styles={styles}
                                    isClearable
                                    options={tagsOptions}
                                    components={{ Option: ListOptions}}
                                    value={filterTagsChecked}
                                    onChange={(e) => setFilterTagsChecked(e)}
                                    isMulti={true}
                                    placeholder="Selecione a(s) tag(s)"
                                    closeMenuOnSelect={false}
                                />   
                            </InputBox>  

                        </DivInputs>
                        
                        <Div>
                            <AdicionarButton
                                type="submit"
                                onClick ={handlePesquisa}> 
                                <h3>Filtrar</h3> 
                            </AdicionarButton> 
                        </Div>
                    </FundoFiltro>
                </ColumnLeft>
                <ColumnRight>
                    <ListaResults>{renderResults}</ListaResults>
                </ColumnRight>
            </Container>
            <Footer/>
        </ThemeProvider>
    </div>
  )
}

export default ResultadosPesquisa