import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

import ReactLoading from "react-loading";

export function AreaSelector({ setArea, areasOptions, loading, onCreateOption }) {

    const [selectedOption, setSelectedOption] = useState([]);
       
    const { Option } = components;
    const AreasOption = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

        useEffect(() => {
            setArea(selectedOption);
        }, [setArea, selectedOption])

    const styles = {
        container: base => ({
            ...base,
            width: "20vw"
        })
    };

    return (
        <CreatableSelect
            styles={styles}
            isClearable
            options={areasOptions}
            components={{ Option: AreasOption }}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e)}
            placeholder="Selecione a área"
            onCreateOption = {onCreateOption}
        />
    );
}