import styled from 'styled-components' 

export const Infos = styled.div`
    margin-top: 20px;
    width: 80%;
    gap: 10px;
    h4{
        color: ${props => props.theme.colors.azul};
        margin-top: 10px;
    }
`
export const Bottom = styled.div`
    width: 230px;
    display: flex;
    justify-content: center;
`

export const EditBtn = styled.button` 
    background-color: ${props => props.theme.colors.verde};
    width: 150px;
    height: 5vh;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    h4{
        color: ${props => props.theme.colors.branco};
    }
    `
