import styled, { css } from "styled-components";

const DragActive = css`
    border-color: ${(props) => props.theme.colors.verde};
`;

const DragReject = css`
    border-color: red;
`;

const FileAccepted = css`
    border-color: ${(props) => props.theme.colors.verde};
`;

export const DropContainer = styled.div.attrs({
    className: "dropzone",
})`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 20vw;
    background-color: ${props => props.theme.colors.branco};
    border: 1px dashed #0490C9;
    border-radius: 4px;
    cursor: pointer;
    transition: height 0.2s ease;
    ${(props) => props.isDragActive && DragActive};
    ${(props) => props.isDragReject && DragReject};
    ${(props) => props.tempFile && FileAccepted};
`;

const messageColors = {
    default: "#0490C9",
    error: "#E42929",
    success: "#004181",
    accepted: "#32CD32",
};

export const UploadMessage = styled.p`
    display: flex;
    color: ${(props) => messageColors[props.type || "default"]};
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    word-break: break-all;
`;
