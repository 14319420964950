import React, {useState} from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../../Styles/Global'
import {theme} from '../../Styles/Theme'
import { Header } from "../../Components/Header";
import {Footer} from "../../Components/Footer";
import { Botao } from '../Login/styles'
import { Background, Container } from './styles';
import { useNavigate } from "react-router-dom";
import api from '../../Services/api';

export default function EsqueciMinhaSenha() {

    const [email, setEmail] = useState("");
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        try {
            const data = {
                email: email,
            };
            const response = await api.post("/esquecisenha", data);
            console.log(response.data);
            localStorage.setItem("email", email);
            const msg = response.data.msg;
            alert(msg)
            navigate("/verificar-pin");
            //Vai levar para a tela de verificar pin
    
        } catch (error) {
            console.log(error);
            
            alert(`E-mail não cadastrado!`);
        }
    };

    return(
        <>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Header />
                <Background>
                    <Container>
                        <h1>Esqueci minha senha</h1>
                        <p>Para redefinir sua senha, informe o e-mail cadastrado na sua conta e lhe enviaremos um link com as instruções</p>
                        <input 
                        placeholder='Email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}/>
                        <Botao  onClick={(event) => handleSubmit(event)}>PRÓXIMO</Botao>
                        <a href = "/login">Cancelar</a>
                    </Container>
                </Background>

                <Footer />
            </ThemeProvider>
        </>
    )
}