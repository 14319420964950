import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../Services/api"

import { FaArrowLeft } from 'react-icons/fa';
import { MdClose } from "react-icons/md";
import ReactLoading from "react-loading"

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { BannerDropzone } from "../../Components/BannerDropzone";

import { ReturnButton } from "../AdicionarArquivo/styles";
import { Container } from "../EditarArquivo/styles"
import { Title } from "../Administracao/styles";
import { Box, InputContainer, Images, BannersList, FileInfo, Preview, DescInput } from "./styles";
import { AdicionarButton } from "../AdicionarArquivo/styles";

export function Banners () {

    const [uploadedBanners, setUploadedBanners] = useState([]);
    const [banner, setBanner] = useState(null);
    const [description, setDescription] = useState("");
    const [loadingGet, setLoadingGet] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [invalidFields, setInvalidFields] = useState(false);

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/gerenciamento`)
    }

    const bannerUpload = async() => {
        setLoadingUpload(true);
        try {
            const formData = new FormData();
            const contentType_map = {
                'image/png':'png',
                'image/jpeg':'jpeg',
                'image/jpg':'jpg',
            }
            const format = contentType_map[banner.type];
            formData.append("media", banner);
            const response = await api.post(`/upload_media?media_format=${format}`, formData);
            const file = {
                media_path: response.data.media_path,
            };
            bannerSubmit(file)
        } catch (error) {
            console.log(error);
            alert("O arquivo de imagem inserido não é suportado!")
        }
        setLoadingUpload(false);
    }

    const bannerSubmit = async(file = {}) => {
        setLoadingUpload(true);
        try {
            const data = {
                banner_name: description,
                ...file
            };
            await api.post("/banner", data);
            setDescription("")
            getBanners();
        } catch (error) {
            console.log(error);
            alert("Erro ao realizar upload da imagem!");
        }
        setLoadingUpload(false);
    };

    const getBanners = async() => {
        setLoadingGet(true);
        try {
            const response = await api.get("/banner");
            const banners = response.data.map((banner) => ({
                banner_name: banner.banner_name,
                id: banner.id,
                media_path: banner.media_path,
                bannerUrl: "https://etarserra.fly.dev/media/" + banner.media_path
            }))
            setUploadedBanners(banners)
        } catch (error) {
            console.log(error)
        } 
        setLoadingGet(false);
      };

    useEffect(() => {
        getBanners();
    },[])

    useEffect(() => {
        if ( !description || !banner )
            {setInvalidFields(true);
        }
        else{
            setInvalidFields(false);
        }
        }, [
            description, banner  
        ]);
      
    const handleDeleteBanner = async(file) => {
        setLoadingDelete(true);
        try {
            console.log(file)
            await api.delete("/media/" + file.media_path);
            getBanners();
        } catch (error) {
            console.log(error);
            alert("Erro ao deletar upload!");
        }
        setLoadingDelete(false);
    };

    const bannerDelete  = async (file) => {
        setLoadingDelete(true);
        try {
            await api.delete("/banner/" + file.id);
            handleDeleteBanner(file);
        } catch (error) {
        console.log(error);
        alert("Erro ao deletar imagem!");
        }
        setLoadingDelete(false)
    }

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>
                <Box>
                    <Title><h1>Imagens da home</h1></Title>
                    <Images>
                        <InputContainer>
                            <BannerDropzone
                                setBanner={setBanner}
                            />
                        </InputContainer>
                        <BannersList>
                            {uploadedBanners.map(uploadedBanner => (
                                <li key={uploadedBanner.id}>
                                    <FileInfo>
                                        <div>
                                            <span>
                                                { !uploadedBanner.url && (<>                                                    
                                                    <button onClick={() => bannerDelete(uploadedBanner)}>
                                                    {loadingDelete ? (<ReactLoading
                                                                    type="spin"
                                                                    color="#0490C9"
                                                                    height={"20px"}
                                                                    width={"20px"}/>
                                                    ) : (<MdClose size={26} color="#0490C9"/>)}
                                                    </button>
                                                    </>
                                                )} 
                                            </span>
                                        </div>
                                        <Preview><img src={uploadedBanner.bannerUrl} alt={uploadedBanner.banner_name} /></Preview>                   
                                    </FileInfo>
                                </li>
                            ))}
                        </BannersList>
                    </Images>
                    <DescInput
                        type="text"
                        placeholder="Descrição da imagem"
                        value={description}
                        onChange={event => setDescription(event.target.value)}
                    />
                    <AdicionarButton
                        type="submit"
                        disabled={invalidFields}
                        onClick={(event) => bannerUpload(event)}> 
                        {loadingUpload ? (
                            <ReactLoading
                                type="spin"
                                color="#ffff"
                                height={"25px"}
                                width={"25px"}/>
                         ) : (
                            <h3>Adicionar imagem</h3>
                        )}
                    </AdicionarButton>  
                </Box>
            </Container>
            <Footer/>
        </>
    )
}