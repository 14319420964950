import React from "react";
import { useNavigate } from "react-router-dom"

import { FaArrowLeft } from 'react-icons/fa';

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";

import { ReturnButton } from "../AdicionarArquivo/styles"
import { Container } from "../EditarArquivo/styles"
import { Box, Title } from "../Administracao/styles";
import { Buttons, EditarFotosBtn, AddColabBtn, EditDeletColabBtn } from "./styles";

export function Gerenciamento() {

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/admin`)
    }

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar</h2> </ReturnButton>
            <Container>
                <Box>
                    <Title> <h1>Gerenciamento</h1></Title>
                    <Buttons>
                        <EditarFotosBtn to={`/banners`}> <h5>Editar fotos da home</h5></EditarFotosBtn>
                        <AddColabBtn to={`/adicionar-colaborador`}> <h5>Adicionar coloborador(a)</h5></AddColabBtn>
                        <EditDeletColabBtn to={`/editar-excluir-colaborador`}> <h5> Editar ou excluir colaborador(a)</h5></EditDeletColabBtn>
                    </Buttons>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}