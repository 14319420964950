import React from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet} from "react-router-dom";

//Pages
import Login from "../Pages/Login/index";
import EsqueciMinhaSenha from "../Pages/EsqueciMinhaSenha/index";
import RedefinirSenha from "../Pages/RedefinirSenha/index";
import Home from "../Pages/Home";
import {AuthProvider} from "../Contexts/AuthProvider";
import { AdicionarArquivo } from "../Pages/AdicionarArquivo";
import { EditarArquivo } from "../Pages/EditarArquivo";
import { EditarMedia } from "../Pages/EditarMedia";
import { Administracao } from "../Pages/Administracao";
import { Gerenciamento } from "../Pages/Gerenciamento";
import { AdicionarColaborador } from "../Pages/AdicionarColaborador";
import { AlterarColaborador } from "../Pages/AlterarColaborador";
import { AlterarColaborador2 } from "../Pages/AlterarColaborador2";
import { AlterarColaborador3 } from "../Pages/AlterarColaborador3";
import { Banners } from "../Pages/Banners";
import { Arquivo } from "../Pages/Arquivo";
import ResultadosPesquisa from "../Pages/ResultadosPesquisa";
import ResultadosTitulos from "../Pages/ResultadosTitulos";
import VerificarPin from "../Pages/VerificarPin/index";
import NotFound from "../Pages/NotFound";

const PrivateRoute = ({isAllowed}) => {
    if (!isAllowed) {
      return <Navigate to="/" />;
    }
  
    return <Outlet />
        
  };

export function AppRoutes(){
    const user = localStorage.getItem("user");
    const isAdmin = localStorage.getItem("isAdmin");


    return(
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element = { <Home /> } exact path = "/" />
                    <Route element = {<Arquivo/>} exact path = "/arquivo/:id" />
                    <Route element = { <Login /> } exact path = "/login" />
                    <Route element = { <EsqueciMinhaSenha /> } exact path = "/esqueci-senha" />
                    <Route element = { <VerificarPin /> } exact path = "/verificar-pin" />
                    <Route element = { <RedefinirSenha /> } exact path = "/redefinir-senha/:id" />
                    <Route element = { <NotFound /> } exact path = "*" />
                    
                    <Route element = { <PrivateRoute isAllowed={!!user} /> }>
                        <Route element = { <AdicionarArquivo /> } exact path = "/adicionar-arquivo"/>
                        <Route element = { <EditarArquivo /> } exact path = "/editar-arquivo/:id" />
                        <Route element = { <EditarMedia /> } exact path = "/editar-media/:id" />
                        <Route element = { <Administracao /> } exact path = "/admin" />   
                    </Route >

                    <Route element = { <PrivateRoute isAllowed={!!user && isAdmin === "true"} /> }>
                        <Route element = { <Gerenciamento /> } exact path = "/gerenciamento" />
                        <Route element = { <AdicionarColaborador /> } exact path = "/adicionar-colaborador" />
                        <Route element = { <AlterarColaborador /> } exact path = "/editar-excluir-colaborador" />
                        <Route element = { <AlterarColaborador2 /> } exact path = "/editar-excluir-colaborador/:id" />
                        <Route element = { <AlterarColaborador3 />} exact path = "/editar-senha-colaborador/:id" />
                        <Route element = { <Banners /> } exact path = "/banners" />                        
                    </Route >
                    <Route Route element = {<ResultadosPesquisa /> } exact path = "/resultados-filtro/:award/:min_year/:max_year/:category/:area/:tags/:authors" />
                    <Route Route element = {<ResultadosTitulos /> } exact path = "/resultados-titulo/:title" />

                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}