import styled from 'styled-components'

export const Background = styled.div`
    padding: 5vh;
    display: flex;
    flex-direction: row;
    height: 65vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.azul};
    h1{
        color: ${props => props.theme.colors.branco};
        font-size: 25vh;
        margin-bottom: 3vh;
        font-style: normal;
        font-weight: 900;
    }
    p{
        color: ${props => props.theme.colors.branco};
        font-size: 4vh;
        font-weight: 600;
        text-align: justify;
    }
`

export const Separacao = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
`