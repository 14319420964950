import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";
import ReactLoading from "react-loading";

import { FaArrowLeft } from 'react-icons/fa';

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { MyDatePicker } from "../../Components/MyDatePicker"
import { MyDropzone }  from "../../Components/MyDropzone";
import { CategoriaSelector } from "../../Components/CategoriaSelector";
import { AreaSelector } from "../../Components/AreaSelector";
import { AutoresSelector } from "../../Components/AutoresSelector";
import { TagsSelector } from "../../Components/TagsSelector";
import { ReturnButton, Container, ColumnLeft, ColumnRight, InputBox, Input, InputDescription, UploadSelection, UploadOptions, InputList, Bottom, AdicionarButton } from './styles'
import api from "../../Services/api"

export function AdicionarArquivo() {

    const {id} = useAuth();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [uploadType, setUploadType] = useState("");
    const [fileUpload, setFileUpload] = useState(null);
    const [fileLink, setFileLink] = useState("")
    const [categoryChecked, setCategoryChecked] = useState([]);
    const [areaChecked, setAreaChecked] = useState([]);
    const [date, setDate] = useState("");
    const [award, setAward] = useState("");
    const [authorsChecked, setAuthorsChecked] = useState([]);
    const [tagsChecked, setTagsChecked] = useState([]);

    const [loading, setLoading] = useState(false);
    const [invalidFields, setInvalidFields] = useState(false);
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [areasOptions, setAreasOptions] = useState([]);
    const [authorsOptions, setAuthorsOptions] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);

    const navigate = useNavigate()

    function handleNavigate(){
        navigate(`/admin`)
    }

    // ---------------------- Post de arquivo do tipo upload --------------------------------

    const fileSubmit = async() => {
        setLoading(true);
        try {
            const formData = new FormData();
            const contentType_map = {
                'image/png':'png',
                'image/jpeg':'jpeg',
                'image/jpg':'jpg',
                'image/gif':'gif',
                'video/mp4':'mp4',
                'video/webm':'webm',
                'application/pdf':'pdf'
            }
            const format = contentType_map[fileUpload.type];
            formData.append("media", fileUpload);
            const response = await api.post(`/upload_media?media_format=${format}`, formData);
            const file = {
                media_path: response.data.media_path,
                type: format
            };
            handleSubmitUpload(file);
        } catch (error) {
            console.log(error);
            alert("Erro ao realizar upload do arquivo!");
        }
        setLoading(false);
    };

    const handleSubmitUpload = async (file = {}) => {
        setLoading(true);
        try {
            var category = [categoryChecked.id];
            var area = [areaChecked.id];
            var year = Number((String(date)).slice(11, 15))
            const data = {
                click_quantity: 0,
                title: title,
                category: category,
                area: area,
                year: year,
                awarded: award,
                description: description,
                tag: tagsChecked.map((tag) => tag.id),
                author: authorsChecked.map((author) => author.id),
                creator: id,
                ...file
            };
            console.log(data)
            await api.post("/file", data);
            handleNavigate();
            alert("Arquivo salvo com sucesso");
        } catch (error) {
            console.log(error);
            alert("Erro ao salvar arquivo!");
        }
        setLoading(false);
    }

    // ---------------------- Post de arquivo do tipo upload --------------------------------


    const handleSubmitLink = async () => {
        setLoading(true);
        try {
            var category = [categoryChecked.id];
            var area = [areaChecked.id];
            console.log((category))
            console.log(id)
            var link = (fileLink.replace("youtu.be/", "youtube.com/embed/").replace("watch?v=", "embed/")).slice(0, 41)
            var year = Number((String(date)).slice(11, 15))
            const data = {
                type: "link",
                click_quantity: 0,
                title: title,
                category: category,
                area: area,
                year: year,
                awarded: award,
                description: description,
                media_path: link, 
                author: authorsChecked.map((author) => author.id),
                tag: tagsChecked.map((tag) => tag.id),
                creator: id,  
            };
            console.log(data)
            await api.post("/file", data);
            alert("Arquivo salvo com sucesso");
        } catch (error) {
            console.log(error);
            alert("Erro ao salvar arquivo!");
        }
        setLoading(false);
    }

    // ---------------------- Detecta se todos os campos foram preenchidos para habilitar o botão --------------------------------


    useEffect(() => {
        if ( !title || !description || !categoryChecked || !areaChecked || !date || !award || !authorsChecked || !tagsChecked )
            {setInvalidFields(true);
        }
        else if ( !fileUpload && !fileLink )
            {setInvalidFields(true);
        }
        else{
            setInvalidFields(false);
        }
    }, [
        title, description, categoryChecked, areaChecked, fileUpload, fileLink, date, award, authorsChecked, tagsChecked  
    ]);

    // ---------------------- Faz requisições de busca e criação de Categorias --------------------------------

    const searchCategory = async () => {
        setLoading(true);
        try {
            const response = await api.get("/category");      //Colocar rota do back
            const categoriesOptions = response.data.map((category) => ({
                id: category.id,
                value: category.name,
                label: category.name,
            }));
            setCategoriesOptions(categoriesOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchCategory();
    }, [categoryChecked]);       

    const createCategory = async (categoryChecked) => {
        setLoading(true);
        try {
            const data = {
                name: categoryChecked,
            };
            await api.post("/category", data); //Colocar rota do back
            searchCategory();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar categoria!");
        }
        setLoading(false);
    };

    // ---------------------- Faz requisições de busca e criação de Áreas --------------------------------

    const searchArea = async () => {
        setLoading(true);
        try {
            const response = await api.get("/area");      //Colocar rota do back
            const areasOptions = response.data.map((area) => ({
                id: area.id,
                value: area.name,
                label: area.name,
            }));
            setAreasOptions(areasOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchArea();
    }, [areaChecked]);       

    const createArea = async (areaChecked) => {
        setLoading(true);
        try {
            const data = {
                name: areaChecked,
            };
            await api.post("/area", data); //Colocar rota do back
            searchArea();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar área!");
        }
        setLoading(false);
    };

    // ---------------------- Faz requisições de busca e criação de Autores --------------------------------

    const searchAuthor = async () => {
        setLoading(true);
        try {
            const response = await api.get("/autores");
            const authorOptions = response.data.map((author) => ({
                id: author.id,
                value: author.name,
                label: author.name,
            }));
            setAuthorsOptions(authorOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchAuthor();
    }, [authorsChecked]);       

    const createAuthor = async (authorsChecked) => {
        setLoading(true);
        try {
            const data = {
                name: authorsChecked,
            };
            await api.post("/autor/create", data);
            searchAuthor();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar autor!");
        }
        setLoading(false);
    };

    // ---------------------- Faz requisições de busca e criação de Tags --------------------------------

    const searchTags = async () => {
        setLoading(true);
        try {
            const response = await api.get("/tags");
            const tagOptions = response.data.map((tag) => ({
                id: tag.id,
                value: tag.name,
                label: tag.name,
            }));
            setTagsOptions(tagOptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        searchTags();
    }, [tagsChecked]);

    const createTag = async (tagsChecked) => {
        setLoading(true);
        try {
            const data = {
                name: tagsChecked,
            };
            await api.post("/tag/create", data);
            searchTags();
        } catch (error) {
            console.log(error);
            alert("Erro ao adicionar tag!");
        }
        setLoading(false);
    };

    return (
        <>
            <Header/>
            <ReturnButton onClick={handleNavigate}> <h2> <FaArrowLeft/> Voltar </h2> </ReturnButton>
            <Container>             
                <ColumnLeft>
                    <InputBox>
                        <h3>Título:</h3>
                        <Input 
                            placeholder=""
                            value={title}
                            onChange={event => setTitle(event.target.value)}>
                        </Input>
                    </InputBox>

                    <InputBox>
                        <h3>Descrição:</h3>
                        <InputDescription
                            placeholder=""
                            value={description}
                            onChange={event => setDescription(event.target.value)}>          
                        </InputDescription>
                    </InputBox>

                    <InputBox>
                        <UploadSelection>
                            <h3>Arquivo:</h3>
                            <UploadOptions
                                value={uploadType}
                                onChange={(event) =>
                                    setUploadType(event.target.value)}
                            >
                                <option disabled={uploadType}>Tipo de arquivo:</option>
                                <option value="upload">Upload de arquivo</option>
                                <option value="link">Link do arquivo</option>
                            </UploadOptions>
                        </UploadSelection>
                        {uploadType === "link" ? (
                            <Input
                                value={fileLink}
                                placeholder="Insira o link do vídeo"
                                onChange={(event) =>
                                    setFileLink(event.target.value)
                                }
                            />
                            
                        ) : (
                            <MyDropzone
                                setFile={setFileUpload}
                                disabled={!uploadType}
                            />     
                        )}
                    </InputBox>

                    <InputBox>
                        <h3>Categoria:</h3>
                        <CategoriaSelector
                            setCategory={setCategoryChecked}
                            categoriesOptions={categoriesOptions}
                            loading={loading}
                            onCreateOption={createCategory}
                        /> 
                    </InputBox>
                </ColumnLeft>      

                <ColumnRight>
                    <InputBox>
                        <h3>Área:</h3>
                        <AreaSelector
                            setArea={setAreaChecked}
                            areasOptions={areasOptions}
                            loading={loading}
                            onCreateOption={createArea}
                        />                 
                    </InputBox>

                    <InputBox>
                        <h3>Ano:</h3>
                        <MyDatePicker
                            setYear={setDate}
                        />                                       
                    </InputBox>  

                    <InputBox>
                        <h3>Autor:</h3>
                        <AutoresSelector
                            setAuthors={setAuthorsChecked}
                            authorsOptions={authorsOptions}
                            loading={loading}
                            onCreateOption={createAuthor}
                        />
                    </InputBox>

                    <InputBox>
                        <h3>Tags:</h3>                       
                        <TagsSelector
                            setTags={setTagsChecked}
                            tagsOptions={tagsOptions}
                            loading={loading}
                            onCreateOption={createTag}
                        />                        
                    </InputBox>
                 
                    <InputBox>
                        <h3>Premiação:</h3>
                        <InputList
                            value={award}
                            onChange={(event) =>
                                setAward(event.target.value)}
                            >
                            <option disabled={award}></option>
                            <option value="Sim">Sim</option>
                            <option value="Não">Não</option>
                            <option value="Não informado">Não informado</option>
                        </InputList>                 
                    </InputBox>      
                </ColumnRight>              
            </Container>
            <Bottom>
                {uploadType === "upload" ? (                    
                        <AdicionarButton
                            type="submit"
                            disabled={invalidFields}
                            onClick={(event) => fileSubmit(event)}> 
                            {loading ? (<ReactLoading
                                    type="spin"
                                    color="#ffffff"
                                    height={"20px"}
                                    width={"20px"}/>
                            ) : (<h3>Adicionar arquivo</h3>)}                              
                        </AdicionarButton>                    
                ) : (
                    <AdicionarButton
                        type="submit"
                        disabled={invalidFields}
                        onClick={(event) => handleSubmitLink(event)}> 
                        {loading ? (<ReactLoading
                                        type="spin"
                                        color="#ffffff"
                                        height={"20px"}
                                        width={"20px"}/>
                        ) : (<h3>Adicionar arquivo</h3>)} 
                    </AdicionarButton>  
                )}
            </Bottom> 
            <Footer/>
        </>
    )
}