import styled from 'styled-components'

export const Box = styled.div`
    background-color: ${props => props.theme.colors.branco};
    height: 55vh;
    width: 100vw;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 40px;
    padding-bottom: 5vh;
    border-radius: 8px;
`;

export const DescInput = styled.input`
    background-color: ${props => props.theme.colors.branco};
    border-radius: 8px;
    border: 2px solid ${props => props.theme.colors.azul}; 
    height: 7vh;
    width: 450px;
    padding-left: 10px;
    margin-bottom: 1%;
    cursor: text;
    textarea:focus, input:focus{
        box-shadow: 0 0 0 0;
        outline: 0;
    }
    ::placeholder{
        color: ${props => props.theme.colors.azul};
    }
`

export const InputContainer = styled.div`
    height: 25vh;
    width: 12vw;
    margin-right: 15px
`;

export const Images = styled.div`
    width: 95%;
    height: 35vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll; 
    ::-webkit-scrollbar {
        height: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: ${props => props.theme.colors.azul}
    }
`

export const BannersList = styled.ul`
    display: flex;
    li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: 15px;
    }
`

export const FileInfo = styled.div`
    height: 25vh;
    width: 12vw;
    border: solid 1px ${props => props.theme.colors.azul};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    div { 
        display: flex;
        justify-content: flex-end;
        width: 170px;
        margin: 5px;
        span {
            font-size: 12px;
            margin-top: 5px;
            button {
                border: 0;
                background: transparent;
                height: 24px;
                color: "#0490C9";
                cursor: pointer;
            }
        }
    }
`;

export const Preview = styled.div`
    img{
        width: 150px;
        height: 100px;
        border-radius: 5px;
        margin-right: 10px;
    }
`