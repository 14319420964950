import styled from 'styled-components'

export const Background = styled.div`
    padding: 5vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.colors.azul};
`

export const Divisao = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 2vh 0vw;
    flex-wrap: wrap;
`

export const Container = styled.div`
    width: 40vw;
    height: 360px;
    padding: 3vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background-color: ${props => props.theme.colors.branco};
`

export const Destaques = styled.div`
    margin: 3vh 0vw;
    display: flex;
    flex-direction: column; 
    h1{
        color: ${props => props.theme.colors.amarelo};
    }
`

export const Botao = styled.button`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.colors.azul};
    color: ${props => props.theme.colors.branco};
    padding: 1vh 2vh;
    border: none;
    border-radius: 1vh;
    font-size: 1em;
    svg{
        font-size: 1.4em;
    }
    p{
        margin-right: 1vw;
    }
`

export const AreaPesquisa = styled.div`
    display: flex;
    flex-direction:row;
    gap: 1.5vh;
    align-items: center;
    margin-top: 10vh;
    input{
        border: solid 0.4vh ${props => props.theme.colors.azul};
        padding: 1vw;
        color: ${props => props.theme.colors.azul};
        border-radius: 0.5vh;
        height: 5vh;
        width: 25vw;

    }
    input::placeholder {
        color: ${props => props.theme.colors.azul};
}
    textarea:focus, input:focus {
        box-shadow: 0 0 0 0;
        outline: 0;
    }
`

export const Titulo= styled.h3`
    color: ${props => props.theme.colors.azul};
    font-size: 1.9em;
    margin-bottom: 2vh;
    display: flex;
   flex-direction: row;
   justify-content: center;
    
`

export const BotaoSair = styled.button`
    cursor: pointer;
    background:none;
    color: ${props => props.theme.colors.azul};
    border: none;
    font-size: 1.4em;
    font-weight:600;
`

export const DivBotao = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
   margin-bottom: 0.5vh;
`

export const InputBox = styled.div`
    background-color: none;
    width:35vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    h3{
        color: ${props => props.theme.colors.azul};
        margin-right: 2vw;
    }
`

export const InputList = styled.select`
    background-color: ${(props) => props.theme.colors.branco};
    border: 3px solid gainsboro;
    height: 5vh;
    width: 20vw;
    border-radius: 5px;
    font-size: medium;
    padding-left: 10px;
    padding-right: 10px;
`;

export const DivInputs = styled.div`
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
`;

export const AdicionarButton = styled.button`
    background-color: ${props => props.theme.colors.azul};
    color: ${props => props.theme.colors.branco};
    box-shadow: #00668e 4px 4px 0px;
    border: none;
    border-radius: 10px;
    height: 5vh;
    width: 15vw;
    transition: transform 200ms, box-shadow 20ms;
    cursor: pointer;
    :active{
        transform: translateY(4px) translateX(4px);
        box-shadow: blue 0px 0px 0px;
    }
`
export const Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vh;
`;
