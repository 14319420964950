import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

import ReactLoading from "react-loading";

export function CategoriaSelector({ setCategory, categoriesOptions, loading, onCreateOption }) {

    const [selectedOption, setSelectedOption] = useState([]);
       
    const { Option } = components;
    const CategoriesOption = (props) =>
        loading ? (
            <ReactLoading
                type="spin"
                color="#0490C9"
                height={"25px"}
                width={"25px"}
            />
        ) : (
            <Option {...props}>{props.data.value}</Option>
        );

        useEffect(() => {
            setCategory(selectedOption);
        }, [setCategory, selectedOption])

    const styles = {
        container: base => ({
            ...base,
            width: "20vw"
        })
    };

    return (
        <CreatableSelect
            styles={styles}
            isClearable
            options={categoriesOptions}
            components={{ Option: CategoriesOption }}
            value={selectedOption}
            onChange={(e) => setSelectedOption(e)}
            placeholder="Selecione a categoria"
            onCreateOption = {onCreateOption}
        />
    );
}