import React, { useState, useEffect } from "react";

import { GoSearch } from 'react-icons/go'
import { AiFillEdit } from 'react-icons/ai'
import { MdLogout } from 'react-icons/md'

import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";

import { ReturnButton } from "../AdicionarArquivo/styles";
import { Container } from "../EditarArquivo/styles";
import { Box, Title, Buttons, GerenciamentoBtn, AdicionarBtn, Search, Input, InputBox, SearchIcon, List, Files, EditarBtn } from "./styles";
import api from "../../Services/api";
import { useAuth } from '../../Hooks/useAuth'

export function Administracao() {
    const { logout } = useAuth();

    const [loading, setLoading] = useState(false);
    const [filesList, setFilesList ] = useState([]);
    const [invalidFields, setInvalidFields] = useState(false)
    const [search, setSearch] = useState("");

    const searchFiles = async () => {
        setLoading(true);
        try {
            const stringRoute = `${search}`;
            const response = await api.get("/file?title=" + stringRoute);
            const files = response.data.map((file) => ({
                id: file.id,
                name: file.title
            }));
            setFilesList(files);
            console.log(files)
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if ( !search )
            {setInvalidFields(true);
        }
        else{
            setInvalidFields(false);
        }
    }, [
        search
    ]);

    const handleSair = async () => {  
        logout();
      };

    const isAdmin = localStorage.getItem("isAdmin");

    return(
        <>
            <Header/>
            <ReturnButton onClick={handleSair}> <h2> <MdLogout/> Sair </h2> </ReturnButton>
            <Container>
                <Box>
                    <Title><h1>Administração</h1></Title>
                    <Buttons>
                        {isAdmin === "true"?
                        (<GerenciamentoBtn to={`/gerenciamento`}> <h5> GERENCIAMENTO </h5></GerenciamentoBtn>) :
                        ( <> </> )}
                        
                        <AdicionarBtn to={`/adicionar-arquivo`}> <h5> ADICIONAR ARQUIVO </h5></AdicionarBtn>
                    </Buttons>
                    <Search>
                        <InputBox>
                            <Input 
                                placeholder="Procure por um arquivo"
                                value={search}
                                onChange={event => setSearch(event.target.value)}>
                            </Input>
                            <SearchIcon onClick={searchFiles} disabled={invalidFields}>
                                <h2><GoSearch/></h2> 
                            </SearchIcon>
                        </InputBox>
                        {!!search && (
                            <List>
                                {
                                    filesList.map((file, index) => {

                                        return(
                                            <Files key={index} to={`/editar-arquivo/${file.id}`} state={file}>
                                                <h2>{file.name}</h2>
                                                <EditarBtn>
                                                    <AiFillEdit/>
                                                </EditarBtn>                                                
                                            </Files>
                                        )
                                    })
                                }
                            </List>)
                        }        
                    </Search>
                </Box>
            </Container>
            <Footer/>
        </>
    )
}