import styled from 'styled-components'
import { Link } from "react-router-dom"

export const Head = styled.header`
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    img{
        height: 15vh;
    }
    h1{
        color: ${props => props.theme.colors.azul};
    }
    div{ 
        height: 15vh;
        width: 15vh;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
            background-color: ${props => props.theme.colors.azul};
            border-radius: 10px;    
            margin-right: 10px;
            height: 80px;
            width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            h1, h3{
                color: ${props => props.theme.colors.branco};
            }
        }
    }
    
`

export const Logo = styled(Link)`
`